import { ActionItem } from "./ActionItem"
import { Advisor } from "./Advisor"
import { AssetClass } from "./InvestmentUniverse"
import { ModelPortfolio } from "./PortfolioModel"

export type ClientStatus = "New client" | "Activity pending" | "New results available" | "Up to date" | "Up for review" | "Archived"

export type InvestmentGoal = "retirementIncome" | "wealthAccumulation" | "retirementDrawdown"

export type InterestOption = "aligning-to-preferences" | "charitable-options" | "marketing-content"

export type Client = {
  _id: string
  advisorId: string

  existingClient?: boolean
  currentPortfolio?: string

  causes?: {
    S1?: { [key: string]: string[] }
  }

  // demographics
  firstName: string
  lastName: string
  email: string
  investmentExperience?: string
  dependents?: string
  employmentType?: string
  jobType?: string
  currentAnnualIncome?: number

  gameTypes?: GameType[]
  wealthClientId?: string

  goals?: GoalsDetails
  pfs?: PersonalFinancialDetails

  // useful for all goals
  investmentAmount: number
  annualInvestmentContribution?: number
  primaryInvestmentGoal?: InvestmentGoal

  // retirement income goal
  dob: string // needs to be ISO date, e.g., "1990-03-15"
  retirementAge?: number
  retirementIncomeGoal?: number
  otherSourcesRetirementIncome?: number
  pensionInRetirement?: number
  alreadyRetired?: boolean
  planningHorizon?: number
  estateGoal?: number

  // wealth accumulation goal
  wealthAccumulationGoal?: number
  wealthAccumulationYear?: number // 4 digit, static year

  //goal projection data
  goalAmount?: number
  goalTargetYear?: number

  portfolio?: {
    current?: Portfolio
    proposed?: Portfolio
  }

  aux?: { [key: string]: any }

  activeGame: Game
  games: Game[]
  events: ClientEvent[]
  notes: Note[]

  status?: ClientStatus
  nextActivityDue?: string

  createdAt: string
  archivedAt: string

  advisor?: Advisor
  interests?: InterestOption[]

  externalId?: string
}

export type Goal = "wealthAccumulation" | "retirementIncome" | "buyAHome" | "travel" | "education" | "createOwnGoal"

export type GoalType = Goal | "startBusiness"

export type GoalDetail = {
  type: GoalType
  id?: string
  name?: string
  completed: boolean
  contributions?: {
    frequency?: "weekly" | "fortnightly" | "monthly" | "quarterly" | "annually"
    percentage?: number
    value?: number
  }
  currentSavings?: {
    value?: number
  }
  downPayment?: {
    frequency?: "weekly" | "fortnightly" | "monthly" | "quarterly" | "annually"
    percentage?: number
    value?: number
  }
  govtIncome?: {
    value?: number
  }
  targetAmount?: {
    frequency?: "weekly" | "fortnightly" | "monthly" | "quarterly" | "annually"
    value?: number
    notApplicable?: boolean
  }
  targetDate?: {
    value?: Date
    notApplicable?: boolean
  }
}


export type AssetType = "property" | "vehicles" | "retirementAccounts" | "investments" | "checkingAccounts" | "savingsAccounts" | "custom"
export type LiabilityType = "mortgage" | "vehicleLoans" | "educationLoans" | "investmentLoans" | "otherLoans" | "creditCardDebt" | "otherDebt" | "custom"
export type IncomeSourceType = "salary" | "retirementIncome" | "businessIncome" | "investmentIncome" | "rentalIncome" | "custom"
export type ExpenseType = "housing" | "utilities" | "vehiclesTransport" | "groceries" | "creditCardPayments" | "entertainment" | "education" | "childSupport" | "investments" | "insurance" | "healthPersonalCare" | "vacations" | "giftsDonations" | "custom"

export type FrequencyOption = "weekly" | "fortnightly" | "monthly" | "quarterly" | "annually"

export type Asset = {
  id?: string
  type: AssetType
  name?: string
  amount?: number
}

export type Liability = {
  id?: string
  type: LiabilityType
  name?: string
  amount?: number
}

export type IncomeSource = {
  id?: string
  type: IncomeSourceType
  name?: string
  amount?: number
  frequency?: FrequencyOption
}

export type Expense = {
  id?: string
  type: ExpenseType
  name?: string
  amount?: number
  frequency?: FrequencyOption
}

export type PersonalFinancialDetails = {
  assets?: Asset[]
  liabilities?: Liability[]
  income?: IncomeSource[]
  expenses?: Expense[]
  otherInfo?: string
  updatedAt?: string
  reportUrl?: string
  reportGeneratedAt?: Date
}

export type GoalsDetails = {
  goalDetails?: GoalDetail[]
  otherInfo?: string
  updatedAt?: string
}

export const MAX_AMOUNT = 1000000000

export interface PortfolioMapping {
  portfolio: ModelPortfolio
  riskComfort?: number
}

export type GameType = "risk" | "esg" | "goals" | "pfs" | "liquidity" | "tax" | "retirement"

export interface Game {
  gameType?: GameType
  experimentGroupId?: string
  token: string
  email: string
  created: Date
  sent: string
  // opened: Date
  // blocked: Date
  // copiedToClipboard: Date
  nextInviteDate: string
  startedAt: string
  played: string
  status: string
  rmJourneyUrl: string
  gameReportUrl: string
  summaryReportUrl: string
  summaryDate: string
  migratedAt?: string
  markedAsReviewedAt?: string
  nextActivityDue?: string
  risk: RiskGameData & { otherInfo?: string }
  esg: EsgGameData & { otherInfo?: string }
  goals: GoalsDetails & { results: {} }
  pfs: PersonalFinancialDetails & { results: {} }
  liquidity: RiskGameData & { otherInfo?: string }
  tax: RiskGameData & { otherInfo?: string }
  retirement: RetirementGameData & { otherInfo?: string }
  selectedPortfolioModel: SelectedPortfolioModel
  events: GameEvent[]

  assetClasses: AssetClass[]
  portfolioMappings: PortfolioMapping[]
  timeHorizon: string
  actionItems: ActionItem[] // Q: should these go to Client?
  // nextMeeting?: Date => it's Client.nextActivityDue
}

export type ClientEventType = "created" | "marked as reviewed"
export type ClientGameEventType = "created" | "game completed" | "marked as reviewed"

export interface ClientEvent {
  created: Date
  createdBy: { sub: string }
  type: ClientEventType
}

export interface GameEvent {
  message: string
  created: Date
  eventType?: ClientGameEventType
}

export interface ChoiceDecision {
  choiceGain?: number
  choiceLoss?: number
  choiceX?: number
  seq?: number
  userDecisionX?: number
  userDecisionY?: number
  interceptX?: number
  interceptY?: number
  maxGain?: number
  maxLoss?: number
}

export interface RetirementGameData {
  decisions?: {
    GI?: ChoiceDecision[]
  }
  results?: {
    GI: {}
  }
}

export interface RiskScores {
  riskTolerance: number
  lossAversion: number
  universalRiskAttitudeScore: number
  cceiScore: number
  scoreParam1: number
  scoreParam2: number
}

export type RiskGameData = {
  decisions?: {
    R?: ChoiceDecision[]
    TE?: ChoiceDecision[]
  }
  results?: {
    R: RiskScores
  }
  smoothed?: {
    decisions?: ChoiceDecision[]
    scores?: RiskScores
  }
  goalProjection?: {
    currentDetails: {
      goalAchievability: number,
      goalAchievabilityRange: string,
      goalAchievabilityRangeTranslationKey: string,
      goalAchievabilityBar: {
        numberOfBars: number
        percentage: number
      }
      currentExpectedValue: number
      highPortfolioProjection: number
      lowPortfolioProjection: number
      otherSavings: number,
      portfolioProjection: number
    }
    graph: {
      goal: number
      portfolioExpectedValues: number[]
      portfolioHighValues: number[]
      portfolioLowValues: number[]
      portfolioYearValues: number[]
    }
    tips: {
      goalTip: number
      contributionTip: {
        annualGain: number
        extraContribution: number
      }
      delayTip: {
        annualGain: number
        years: number
      }
    }
  }
}

export interface Score {
  id: string
  score: number
  weight: number
}

export type Fund = {
  id: string
  name?: string
  ticker?: string
  assetClass?: string
  sedol?: string
  sdgAlignScores?: number[]
}

export type SustainabilityMatchScore = {
  fund?: Fund
  score: number
}

export type EsgGameData = {
  decisions?: {
    S1?: ChoiceDecision[]
    S2?: ChoiceDecision[]
  }
  results?: {
    S1: {
      [key: string]: Score
    }
    S2: {
      [key: string]: Score
    }
    impactScore: number
    rho: number
    topCause: string
    sustainabilityMatchScores?: SustainabilityMatchScore[]
    portfolioMatch: PortfolioMatch
  }
  causes?: {
    S1?: { [key: string]: string[] }
    S2?: { [key: string]: string[] }
  }
}

export type PortfolioMatch = {
  fundMatches?: FundAllocation[]

  themeRatings?: number[]
  smScore?: number
  growthAllocationSum: number
  incomeAllocationSum: number
  cashAllocationSum: number
  lastUpdatedAt?: string
}

export interface RiskResult {
  choiceGain: number
  choiceLoss: number
  choiceX: number
  seq: number
}

export interface Note {
  message: string
  created: Date
  createdBy: { sub: string }
  custom?: boolean
}

interface PortfolioModelWeightGroup {
  assetClass: {
    name: string
  }
  totalWeight: number
  totalWeightPercentage: number
}

interface SelectedPortfolioModel {
  comfortLevel: number
  goalAchievability: number
  portfolioModelName: string
  modelWeightGroupList: PortfolioModelWeightGroup[]
}

export type Portfolio = {
  name?: string
  fundAllocations?: FundAllocation[]
  themeRatings?: number[]
  growthAllocationSum?: number
  incomeAllocationSum?: number
  cashAllocationSum?: number
  smScore?: number
  lastUpdatedAt?: string
}

export type FundAllocation = {
  fund?: Fund
  deletedAt?: Date
  allocation?: number
  themeRatings?: number[]
  growthPercent?: number
  incomePercent?: number
  smScore?: number
}
