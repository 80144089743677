
import { GameType, Client } from "../../../../../models/Client"
import { Household } from "../../../../../models/Household"
import GameComment from "./GameComment"

interface Props {
  gameType: GameType
  client?: Client
  household?: Household
}

const CommentsSection: React.FC<Props> = ({ gameType, client, household }) => {
  const clientGames = (
    household
      ? household.members
          .flatMap(({ client }) => client.games?.filter((g) => g.gameType === gameType && g.played).map((game) => ({ game, client })))
          .filter((_) => _)
      : client?.games?.filter((g) => g.gameType === gameType && g.played).map((game) => ({ game, client }))
  )?.sort((game1, game2) => game2.game.experimentGroupId!.localeCompare(game1.game.experimentGroupId!))

  return (
    <section className="flex flex-col gap-6 py-10 px-16">
      <h2 className="text-h3 font-semibold">Additional information from your {household ? "clients" : "client"}</h2>
      <div className="bg-surface-100 flex flex-col w-full">
        {clientGames && clientGames.length > 0 ? (
          clientGames?.map(({ client, game }, i) => (
            <GameComment
              key={game.experimentGroupId}
              client={client}
              game={game}
              showBottomBorder={i !== clientGames.length - 1}
              comment={game[gameType]?.otherInfo}
            />
          ))
        ) : (
          <div className="text-center p-4">There is no information currently available</div>
        )}
      </div>
    </section>
  )
}

export default CommentsSection
