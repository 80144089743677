import { Trans } from "@lingui/react"
import { AssetType, LiabilityType, IncomeSourceType, ExpenseType } from "../../../../../models/Client"

const PlaceholderRow = ({ type }: { type: AssetType | LiabilityType | IncomeSourceType | ExpenseType }) => {
  return (
    <div className="flex justify-between py-4 px-3 border-b border-b-surface-300 text-sec">
      <h4>
        <Trans id={`pfs-title-${type}`} />
      </h4>
      <span className="text-main-300">
        <Trans id="currency">$</Trans>
      </span>
    </div>
  )
}

export default PlaceholderRow
