import { Trans } from "@lingui/macro"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import infoAlert from "../../../assets/icons/info-alert.svg"
import Checkbox from "../../../components/Checkbox/Checkbox"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import { useTheme } from "../../../contexts/ThemeContext"
import { tt } from "../../../lib/translations"
import { Client, GameType } from "../../../models/Client"
import { Household } from "../../../models/Household"

const ActivitySettingsModal = ({ client, household, onClose }: { client?: Client; household?: Household; onClose: () => void }) => {
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [selectedGames, setSelectedGames] = useState<{ [clientId: string]: GameType[] }>({})
  const [isError, setIsError] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [isAllChecked, setIsAllChecked] = useState<{ [clientId: string]: boolean }>({})
  const isValid = useCallback(() => {
    return Object.values(selectedGames).every((gamesArray) => gamesArray.length >= 1)
  }, [selectedGames])

  useEffect(() => {
    if (household) {
      setSelectedGames(
        household.members.reduce((acc, { client }) => {
          acc[client._id] = client.gameTypes ?? firm?.config?.supportedGames?.map((g) => g.type) ?? []
          return acc
        }, {} as { [clientId: string]: GameType[] })
      )
    } else if (client) {
      setSelectedGames({ [client._id]: client.gameTypes ?? firm?.config?.supportedGames?.map((g) => g.type) ?? [] })
    }
  }, [client, firm?.config?.supportedGames, household])

  const { mutate: updateGameTypes, status: clientUpdateState } = useMutation({
    mutationFn: async () => {
      return client
        ? (updateClient(client._id, {
            gameTypes: selectedGames[client._id]
          }) as Promise<Client>)
        : (updateHousehold(household!._id, Object.values(selectedGames).map((d) => ({ gameTypes: d })) ?? [], {}) as Promise<Household>)
    },
    onSuccess: onClose
  })

  const onChange = useCallback(
    (client: Client, gameType: GameType, isSelected: boolean) => {
      setSelectedGames((prevSelectedGames) => {
        const currentGames = prevSelectedGames[client._id] || []
        const updatedGames = isSelected
          ? currentGames.includes(gameType)
            ? currentGames
            : [...currentGames, gameType]
          : currentGames.filter((g) => g !== gameType)
        return {
          ...prevSelectedGames,
          [client._id]: updatedGames
        }
      })
      setIsAllChecked({ ...isAllChecked, [client._id]: false })
    },
    [isAllChecked]
  )

  const onApply = useCallback(() => {
    setShouldValidate(true)
    setIsError(!isValid())
    isValid() && updateGameTypes()
  }, [isValid, updateGameTypes])

  useEffect(() => {
    if (shouldValidate) {
      setIsError(!isValid())
    }
  }, [isValid, shouldValidate])

  const order = theme.games.map((game) => game.type)
  const sortedSupportedGames = firm?.config?.supportedGames?.sort((a, b) => {
    return order.indexOf(a.type) - order.indexOf(b.type)
  })

  const allGameTypes = useMemo(
    () =>
      firm?.config?.supportedGames
        ?.sort((a, b) => {
          return order.indexOf(a.type) - order.indexOf(b.type)
        })
        .map(({ type }) => type) ?? [],
    [firm?.config?.supportedGames, order]
  )

  const checkAllActivitiesHandler = useCallback(
    (id: string, isChecked: boolean) => {
      const updatedGames = isChecked ? allGameTypes : []
      const updatedCheckAll = { ...isAllChecked, [id]: isChecked }
      setSelectedGames((prev) => ({ ...prev, [id]: updatedGames }))

      if (household) {
        household.members.forEach(({ client }) => {
          if (client._id === id) {
            setSelectedGames((prev) => ({ ...prev, [id]: updatedGames }))
            setIsAllChecked(updatedCheckAll)
          }
        })
      }
    },
    [allGameTypes, isAllChecked, household]
  )

  useEffect(() => {
    if (household) {
      const updatedState = household.members.reduce<Record<string, boolean>>((acc, { client }) => {
        acc[client._id] = allGameTypes?.length === selectedGames[client._id]?.length
        return acc
      }, {})
      setIsAllChecked((prev) => ({ ...prev, ...updatedState }))
    }

    if (client) {
      setIsAllChecked((prev) => ({
        ...prev,
        [client._id]: allGameTypes?.length === selectedGames[client._id]?.length
      }))
    }
  }, [allGameTypes.length, client, household, selectedGames])

  return (
    <div className="reprofile-efp-modal-wrapper w-full overflow-y-scroll no-scrollbar max-h-full">
      <div className="flex flex-col items-start gap-y-6">
        <h2 className="text-h2 text-left font-semibold text-main-600">Activity settings</h2>
        <p>
          {household
            ? "Choose which activities you want enabled for your household members."
            : `Choose which activities you want enabled for ${client?.firstName}.`}
        </p>
        <div className="w-full flex flex-col">
          <div className="flex w-full pr-4 mb-2">
            <h3 className="text-sec font-semibold text-main-600 w-full">Activity</h3>
            {household ? (
              <div className="flex w-full">
                <div className="flex w-full pl-1.5">
                  {household.members.map(({ client }) => (
                    <div key={client._id} className="pb-1 text-center flex w-full justify-end">
                      <Checkbox
                        checked={isAllChecked[client?._id]}
                        onChange={(e) => checkAllActivitiesHandler(client?._id, e.target.checked)}
                        className="justify-end flex flex-row-reverse"
                        name={client.firstName}
                        label={client?.firstName}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex justify-end w-full pr-1">
                <Checkbox
                  checked={isAllChecked[client?._id ?? ""]}
                  onChange={(e) => checkAllActivitiesHandler(client?._id ?? "", e.target.checked)}
                  className="justify-end flex flex-row-reverse"
                  name="Select all"
                  label="Select all"
                />
              </div>
            )}
          </div>
          {household ? (
            <>
              <div className="bg-surface-100 py-3 px-4">
                {sortedSupportedGames?.map((game) => (
                  <div key={game.type} className="flex">
                    <div className="w-full py-1">
                      <p>{tt({ id: `game-${game.type}-title` })}</p>
                    </div>
                    <div className="flex w-full justify-between">
                      {household.members.map(({ client }) => (
                        <div key={client._id} className="py-1 w-full text-center flex justify-end">
                          <Checkbox
                            name={game.type}
                            className="justify-center"
                            checked={selectedGames?.[client!._id]?.includes(game.type)}
                            onChange={(event) => onChange(client!, game.type, event.target.checked)}
                            aria-label={`${tt({ id: `game-${game.type}-title` })}-${client.firstName}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="w-full bg-surface-100 py-3 px-4">
              {firm?.config?.supportedGames?.map((game) => {
                return (
                  <Checkbox
                    key={game.type}
                    name={game.type}
                    checked={selectedGames?.[client!._id]?.includes(game.type)}
                    onChange={(event) => onChange(client!, game.type, event.target.checked)}
                    className="flex-row-reverse justify-between w-full p-1"
                    label={tt({ id: `game-${game.type}-title` })}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div role="alert" className="flex flex-col items-center">
        {clientUpdateState === "error" && <div className="text-error text-right mt-6">Error updating the client details</div>}
        {isError && (
          <div className="text-input-error w-full flex items-center mt-1">
            <img alt="" className="text-input-error-icon mr-1" src={infoAlert} aria-hidden />
            <p className="text-input-error-text text-sm text-left text-error font-normal">
              {household ? (
                <Trans id="activity-settings-error-household-text">You must have at least one activity enabled for each household member</Trans>
              ) : (
                <Trans id="activity-settings-error-client-text">You must have at least one activity enabled</Trans>
              )}
            </p>
          </div>
        )}
      </div>
      <div className="w-full flex flex-shrink-0 flex-wrap items-center justify-center mt-6">
        <div className="flex gap-5">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button onClick={onApply} disabled={clientUpdateState === "loading"} className="btn btn-primary btn-medium w-44">
            {clientUpdateState === "loading" ? <Loading type="dots" /> : <span className="whitespace-nowrap">Apply</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ActivitySettingsModal
