import { clsx } from "clsx"
import { useContext, useLayoutEffect } from "react"
import { NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom"
import comingSoonHero from "../../../assets/images/maintenance.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { FirmContext } from "../../../contexts/FirmContext"
import { useTheme } from "../../../contexts/ThemeContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"
import PFSTab from "./PFS/PFSTab"
import RiskTab from "./Risk/RiskTab"
import GoalsTab from "./Goals/GoalsTab"

const ComingSoon = () => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-6">
    <img src={comingSoonHero} alt="Road works sign" />
    <div className="flex flex-col items-center gap-2">
      <h4 className="text-h1 font-semibold">This feature is coming soon</h4>
      <p>Watch this space</p>
    </div>
  </div>
)

const ResultsPage = () => {
  const { firm } = useContext(FirmContext)
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = client || household
  const theme = useTheme()

  const ToFirstTab = () => {
    const navigate = useNavigate()
    useLayoutEffect(() => {
      // Filter theme.games based on firm.config.supportedGames and then find the first tab
      const firstTabType = theme.games
        ?.filter((themeGame) => firm?.config?.supportedGames?.some((sg) => sg.type === themeGame.type))
        ?.find(
          (game) =>
            client?.games?.some((g) => g.gameType === game.type) ||
            household?.members?.some((member) => member.client.games?.some((g) => g.gameType === game.type))
        )?.type
      navigate(firstTabType ?? "pfs", { replace: true }) // Navigate to the found game type or default to "pfs"
    }, [navigate])
    return null
  }

  if (!clientOrHousehold) {
    return <Loading />
  } else {
    return (
      <>
      <div className="relative bg-white h-full py-10 text-main-500">
        <nav className="w-full px-16 font-semibold">
          <ul className="flex overflow-x-auto no-scrollbar">
            {theme.games?.map((game) => {
              if (firm?.config?.supportedGames?.some((sg) => sg?.type === game?.type) || game.type === "pfs" || game.type === "goals") {
                // PFS and Goals tabs should always be present
                const clientGamePlayed = client && latest(client, game.type)?.played
                const householdGamePlayed = household?.members?.some((member) => {
                  return latest(member.client, game.type)?.played
                })
                // Disable the tab only if the game is neither "pfs" nor "goals" and hasn't been played by anyone
                const isTabDisabled = game.type !== "pfs" && game.type !== "goals" && (household ? !householdGamePlayed : !clientGamePlayed)
                const linkStyle = ({ isActive }: { isActive: boolean }) =>
                  clsx(
                    "px-3 py-3.75",
                    isActive ? "border-b-2 border-b-black font-bold" : "font-semibold",
                    isTabDisabled ? "text-main-200 cursor-default" : "text-interactive-500"
                  )
                return (
                  <li key={game.type} className="py-4 text-sec flex-shrink-0 flex-nowrap">
                    <NavLink
                      end
                      className={linkStyle}
                      to={game.type}
                      onClick={(e) => isTabDisabled && e.preventDefault()}
                      aria-disabled={isTabDisabled}
                      replace={!!isTabDisabled}
                    >
                      {tt({ id: `game-${game.type}-title` })}
                    </NavLink>
                  </li>
                )
              }
            })}
          </ul>
        </nav>
        <div className="w-full px-16">
          <div className="border-b border-b-surface-300" />
        </div>
        <div className="h-full overflow-y-auto no-scrollbar">
          <Routes>
            <Route index element={<ToFirstTab />} />
            <Route path="pfs" element={<PFSTab client={client} household={household} />} />
            <Route path="goals" element={<GoalsTab client={client} household={household} />} />
            <Route path="risk" element={<RiskTab client={client} household={household} />} />
            <Route path="liquidity" element={<ComingSoon />} />
            <Route path="esg" element={<ComingSoon />} />
            <Route path="retirement" element={<ComingSoon />} />
            <Route path="tax" element={<ComingSoon />} />
            <Route path="*" element={<div className="full-flex-content-center text-error">We have encountered a configuration error.</div>} />
          </Routes>
        </div>
      </div>
      </>
    )
  }
}

export default ResultsPage
