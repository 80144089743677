import clsx from "clsx"
import { addMonths, formatISO } from "date-fns"
import { useContext, useEffect, useRef, useState } from "react"
import { markAsReviewed } from "../../../../api/advisors"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import errorAlert from "../../../../assets/icons/info-alert.svg"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import Modal from "../../../../components/Modal/Modal"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../contexts/FirmContext"
import { ActionItem } from "../../../../models/ActionItem"
import { Client, Game } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { ModelPortfolio } from "../../../../models/PortfolioModel"
import chevUp from "../../../../pages/advisor/assets/images/chevron-up.svg"
import RMJActionItemNotes from "../../../rmjourney/components/RMJActionItemNotes/RMJActionItemNotes"
import RMJNextMeetingSelection from "../../../rmjourney/components/RMJNextMeetingSelection/RMJNextMeetingSelection"

interface Props {
  client?: Client
  household?: Household
  game?: Game
  onClose: () => void
}

const ResultsReviewedConfirmationModal = ({ client, household, game, onClose }: Props) => {
  const { updateClient, updateHousehold, replace } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const [status, setStatus] = useState<"updating" | "complete" | "error">()
  const [selectedPortfolio, setSelectedPortfolio] = useState<ModelPortfolio | null>()
  const [selectedPortfolioError, setSelectedPortfolioError] = useState<boolean>()
  const [nextMeetingDate, setNextMeetingDate] = useState<number>(12)
  const [actionItems, setActionItems] = useState<ActionItem[]>([])
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuTriggerEl = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if ((client ?? household)?.currentPortfolio) {
      setSelectedPortfolio(firm?.modelPortfolios?.find((pf) => pf.id === (client ?? household)?.currentPortfolio))
    }
  }, [client, household, firm?.modelPortfolios])

  const onConfirm = () => {
    if (selectedPortfolio === undefined) {
      setSelectedPortfolioError(true)
    } else {
      setStatus("updating")
      const nextActivityDue = formatISO(new Date(addMonths(new Date(), nextMeetingDate)).setHours(0, 0, 0, 0))
      const req: ClientUpdateRequest | HouseholdUpdateRequest = {
        actionItems,
        nextActivityDue,
        currentPortfolio: selectedPortfolio?.id
      }

      ;(client ? updateClient(client._id, req) : updateHousehold(household!._id, [], req))
        .then(() => {
          markAsReviewed((client ?? household)!.advisorId!, client ? "client" : "household", client ? client._id : household!._id, {
            ...(game ? { gameId: game.experimentGroupId, nextActivityDue } : {})
          })
            .then((updated) => {
              replace(updated)
              setStatus("complete")
              onClose()
            })
            .catch((err: any) => {
              setStatus("error")
              console.error("error marking the profile as reviewed", err)
            })
        })
        .catch((err: any) => {
          setStatus("error")
          console.error("error updating action items", err)
        })
    }
  }

  useEffect(() => {
    if (selectedPortfolio !== undefined) {
      setIsMenuOpen(false)
      setSelectedPortfolioError(false)
    }
  }, [selectedPortfolio])

  return (
    <Modal contentClassName="!px-0 !py-0 w-modal" handleClose={status !== "updating" ? onClose : undefined}>
      <div className="flex flex-col p-10 overflow-y-scroll no-scrollbar max-h-full">
        <h2 className="text-h2 text-main-600 font-semibold text-center mb-6">
          Are you sure you want to mark {household ? "this household's" : `${client?.firstName}'s`} recent results as reviewed?
        </h2>
        <p className="text-main-600 mb-6">
          {household ? "The household" : `${client?.firstName}'s`} results will be accessible via the report history, however you won’t be able to walkthrough
          the results presentation with them.
        </p>
        <hr className="mb-6 border-surface-300" />
        <div className="border-b border-surface-300 mb-6 pb-6">
          <h3 className="text-p text-main-600 font-bold mb-3">Please confirm {household ? "the household's" : `${client?.firstName}'s`} agreed risk level</h3>
          <div className="w-full flex-col relative inline-block z-40 flex-shrink-0">
            <button
              className={clsx("px-4 py-2 select-advisor-wrapper w-full h-full flex justify-between border bg-white overflow-hidden", {
                "border-error": selectedPortfolioError,
                "border-main-400": !isMenuOpen && !selectedPortfolioError,
                "border-focuscolor": isMenuOpen && !selectedPortfolioError
              })}
              onClick={() => {
                setIsMenuOpen(!isMenuOpen)
              }}
              ref={menuTriggerEl}
              id="select-adviser"
            >
              {selectedPortfolio ? (
                selectedPortfolio.name
              ) : selectedPortfolio === null ? (
                "No selected investment"
              ) : (
                <span className="text-main-300">Select risk level</span>
              )}
              <img src={chevUp} alt="" aria-hidden="true" className={clsx({ "rotate-180": !isMenuOpen })} />
            </button>
            {isMenuOpen && (
              <Dropdown overlayClassName="w-full h-48" handleClose={() => setIsMenuOpen(false)} trigger={menuTriggerEl}>
                <ul
                  className={clsx("list-none p-0 text-main-500 overflow-y-auto border h-48", {
                    "border-focuscolor": isMenuOpen
                  })}
                >
                  <li>
                    <button
                      className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                      onClick={() => {
                        setSelectedPortfolio(null)
                      }}
                    >
                      No selected investment
                    </button>
                  </li>
                  {firm?.modelPortfolios?.map((portfolio) => (
                    <li key={portfolio.id}>
                      <button
                        className="btn p-4 hover:bg-interactive-100 cursor-pointer border-0 block w-full text-left font-normal"
                        onClick={() => {
                          setSelectedPortfolio(portfolio)
                        }}
                      >
                        {portfolio.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </Dropdown>
            )}
          </div>
          <div className="text-input-error flex items-center mt-1" role="alert">
            {selectedPortfolioError && (
              <>
                <img alt="" aria-hidden="true" className="text-input-error-icon mr-1" src={errorAlert} />
                <p className="text-input-error-text text-sm text-error font-normal">Please select an investment risk level</p>
              </>
            )}
          </div>
        </div>
        <RMJActionItemNotes
          client={client}
          household={household}
          title="Add note"
          onAddRemoveNote={(items: ActionItem[]) => {
            setActionItems(items)
          }}
          contentClassName="max-h-20 overflow-y-auto"
        />
        <div className="pb-6">
          <h3 className="text-p text-main-600 font-bold mb-3">Next meeting</h3>
          <fieldset>
            <legend className="text-sec text-main-600 font-semibold mb-1">Select date</legend>
            <RMJNextMeetingSelection
              onSelect={(selected: number) => {
                setNextMeetingDate(selected)
              }}
            />
          </fieldset>
        </div>
        <div role="alert">{status === "error" && <p className="text-error">Error, please try again.</p>}</div>
        <div className="flex justify-center gap-4">
          <button className="w-48 btn btn-secondary btn-medium" disabled={status === "updating"} onClick={onClose}>
            Cancel
          </button>
          <button className="w-48 btn btn-primary btn-medium" disabled={status === "updating"} onClick={onConfirm}>
            {status === "updating" ? <Loading type="dots" /> : "Confirm"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ResultsReviewedConfirmationModal
