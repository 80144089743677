import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { useMemo, useState } from "react"
import { useParams } from "react-router"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { allAssetClasses } from "../../clients/reports/ComfortMatchPage"
import riskComfortIcon from "../assets/images/riskComfort.svg"
import ChartDisplayToggle from "../goalExplorer/components/ChartDisplayToggle/ChartDisplayToggle"
import chartIcon from "./assets/chart.svg"
import listIcon from "./assets/list.svg"
import menuIcon from "./assets/menu.svg"
import pieChartFillIcon from "./assets/pie-chart-fill.svg"
import RMJPortfolioComfortChart from "./RMJPortfolioComfortChart/RMJPortfolioComfortChart"
import RMJPortfolioComfortMatchListView from "./RMJPortfolioComfortMatchListView"
import RMJPortfolioComfortMoreInfoModal from "./RMJPortfolioComfortMoreInfoModal/RMJPortfolioComfortMoreInfoModal"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"

export const AVATAR_ICON_FILL_CLASSES = ["fill-avatar-0-500", "fill-avatar-1-500"]
export const AVATAR_ICON_BG_CLASSES = ["bg-avatar-0-500", "bg-avatar-1-500"]

const RMJPortfolioComfort = ({ translationContext = "client" }: { translationContext?: "advisor" | "client" }) => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = client ?? household
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false)
  const [displayType, setDisplayType] = useState<"chart" | "list">("chart")

  const completedMembers = household?.members?.filter((member) => latest(member.client, "risk")?.played)

  const game = completedMembers?.length === 1 ? latest(completedMembers[0].client, "risk") : latest(clientOrHousehold!, "risk")
  const portfolioMappings = game?.portfolioMappings

  const comfortMatches = useMemo(() => {
    return [...(portfolioMappings || [])].sort((a, b) => {
      const d = b.riskComfort! - a.riskComfort!
      return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
    })
  }, [portfolioMappings])

  const comfortMatch = comfortMatches[0]

  const assetClasses = clientOrHousehold ? allAssetClasses(game?.assetClasses ?? []) : {}

  return (
    <div className="w-full h-full flex flex-col items-start px-4">
      {!portfolioMappings ? (
        <Loading />
      ) : (
        <>
          <div className="w-full flex justify-between items-center mb-5">
            <div>
              <h1 className="text-sec text-main-600 mb-1 uppercase">Risk Comfort</h1>
              <h2 className="text-h3 font-semibold text-main-600">
                {client || (household && completedMembers?.length === 1)
                  ? tt({
                      id: `portfolio-comfort-title-individual-${translationContext}`,
                      message: "Your Risk Comfort with each of our investment options",
                      values: { firstName: household ? completedMembers![0].client.firstName : client?.firstName }
                    })
                  : tt({
                      id: `portfolio-comfort-title-household-${translationContext}`,
                      message: "Your Risk Comfort with each of our investment options",
                      values: { firstName: household?.members[0].client.firstName, secondName: household?.members[1].client.firstName }
                    })}
              </h2>
            </div>
            <ChartDisplayToggle
              toggle1={<img src={chartIcon} alt="Chart view" />}
              toggle2={<img src={listIcon} alt="List view" />}
              onClick={(value) => setDisplayType(value)}
              valueDisplayType={displayType!}
            />
          </div>
          {displayType === "chart" ? (
            <>
              <div className="relative min-h-[360px] w-full grow">
                <RMJPortfolioComfortChart allAssetClasses={assetClasses} client={client} household={household} game={game} comfortMatch={comfortMatch} />
              </div>
              <div className="w-full flex justify-center text-sm">
                <div className="w-12" />
                <div className="h-8 flex flex-grow justify-center items-center gap-5 shrink-0">
                  <p className="flex gap-1 items-center">
                    <img alt="" className="w-3 shrink-0" src={riskComfortIcon} aria-hidden />
                    Highest Risk Comfort
                  </p>
                  {clientOrHousehold?.currentPortfolio && (
                    <p className="flex gap-1 items-center">
                      <img alt="" className="w-2.5 shrink-0" src={pieChartFillIcon} aria-hidden />
                      Current investment
                    </p>
                  )}
                  {household?.members
                    ?.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
                    .map(({ client }, i) => (
                      <p className="flex gap-1 items-center" key={client._id}>
                        <div className={clsx("w-2 h-2 rounded-full", AVATAR_ICON_BG_CLASSES[i])} />
                        {client.firstName}
                      </p>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <div className="w-full">
              <RMJPortfolioComfortMatchListView
                forReport={false}
                allAssetClasses={assetClasses}
                client={client}
                household={household}
                comfortMatch={comfortMatch}
                portfolioMappings={portfolioMappings}
              />
            </div>
          )}
          <button
            className="btn btn-text btn-small btn-secondary text-xs px-3 ml-10 -m-4 h-12 flex gap-x-1 items-center"
            onClick={() => setIsMoreInfoModalOpen(true)}
          >
            <img src={menuIcon} aria-hidden />
            View strategic asset allocation
          </button>
        </>
      )}
      <AnimatePresence>
        {isMoreInfoModalOpen && (
          <RMJPortfolioComfortMoreInfoModal
            assetClasses={game?.assetClasses}
            comfortMatch={comfortMatch}
            onClose={() => setIsMoreInfoModalOpen(false)}
            portfolioMappings={portfolioMappings}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RMJPortfolioComfort
