import { Trans } from "@lingui/react"
import { clsx } from "clsx"
import React from "react"
import infoAlert from "../../../../../assets/icons/info-alert.svg"
import trash from "../../../../../assets/icons/trash.svg"
import AmountFrequencyInput from "../../../../../components/AmountFrequencyInput/AmountFrequencyInput"
import { useTheme } from "../../../../../contexts/ThemeContext"
import useNumberFormatter from "../../../../../hooks/useNumberFormatter"
import { tt } from "../../../../../lib/translations"
import { Expense, MAX_AMOUNT, PersonalFinancialDetails } from "../../../../../models/Client"
import PlaceholderRow from "../AssetsList/PlaceholderRow"

interface Props {
  expense: Expense
  isEditing: boolean
  lastUpdatedDate?: string
  pfsData?: PersonalFinancialDetails
  onChange: (updated: PersonalFinancialDetails) => void
  onRemove?: (id?: string) => void
}

const ExpenseRow: React.FC<Props> = ({ expense, isEditing, lastUpdatedDate, pfsData, onChange, onRemove }) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  const isCustom = expense.type === "custom"
  const data = isCustom ? expense : pfsData?.expenses?.find((a) => a.type === expense.type)
  const error = data?.amount && data.amount > MAX_AMOUNT ? tt({ id: "pfs-results-page-input-amount-range-error-text" }) : undefined

  if (!lastUpdatedDate && !isEditing) {
    return <PlaceholderRow type={expense.type} />
  }

  return (
    <div className="flex flex-col items-center p-3 border-b border-b-surface-300 text-sec">
      <div className="flex justify-between gap-x-2 items-center w-full">
        <label className="whitespace-nowrap overflow-hidden text-ellipsis" htmlFor={isCustom ? expense.id! : expense.type}>
          {isCustom ? expense.name : <Trans id={`pfs-title-${expense.type}`} />}
        </label>
        {isEditing ? (
          <div className="flex gap-x-2 items-center">
            <AmountFrequencyInput
              id={isCustom ? expense.id! : expense.type}
              className={!isCustom ? "mr-8" : undefined}
              inputClassName="!w-25"
              inputFieldClassName="text-sec"
              selectClassName="w-[87px]"
              frequencyValue={data?.frequency ?? "monthly"}
              onFrequencyChange={(frequency) =>
                onChange({
                  ...pfsData,
                  expenses: !pfsData?.expenses?.find((a) => a.type === expense.type && a.id === expense.id)
                    ? [...(pfsData?.expenses ?? []), { type: expense.type, frequency }]
                    : pfsData?.expenses?.map((prevAsset) =>
                        prevAsset.type === expense.type && prevAsset.id === expense.id ? { ...prevAsset, frequency } : prevAsset
                      )
                })
              }
              amountValue={data?.amount}
              onAmountChange={(amount) =>
                onChange({
                  ...pfsData,
                  expenses: !pfsData?.expenses?.find((a) => a.type === expense.type)
                    ? [...(pfsData?.expenses ?? []), { type: expense.type, amount }]
                    : pfsData?.expenses?.map((prevAsset) => (prevAsset.type === expense.type ? { ...prevAsset, amount } : prevAsset))
                })
              }
              isError={!!error}
            />
            {isCustom && onRemove && (
              <button onClick={() => onRemove(expense.id)} className="mr-1">
                <img src={trash} alt="Remove expense" aria-hidden />
              </button>
            )}
          </div>
        ) : (
          <span className={clsx(!lastUpdatedDate ? "text-main-300" : "text-positive-600")}>
            <Trans id="currency">$</Trans>
            {data?.amount && `${numberFormatter.format(data.amount)}${tt({ id: `${data.frequency}-option-postfix` })}`}
          </span>
        )}
      </div>
      <div role="alert" className="w-full">
        {error && isEditing && (
          <div className="text-error flex gap-x-1 text-sm w-full text-left mt-1">
            <img src={infoAlert} alt="" aria-hidden />
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpenseRow
