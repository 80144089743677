import { useTheme } from "../contexts/ThemeContext"
import { tt } from "../lib/translations"
import { Client } from "../models/Client"
import { Household } from "../models/Household"
import useNumberFormatter from "./useNumberFormatter"

const frequencyMultiplierToAnnual = {
  weekly: 52,
  fortnightly: 26,
  quarterly: 36,
  monthly: 12,
  annually: 1
}

const useClientFinances = (ch?: Client | Household) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  const totalAssets = (ch?.pfs?.assets || []).reduce((acc, asset) => acc + (asset.amount || 0), 0)
  const totalLiabilities = (ch?.pfs?.liabilities || []).reduce((acc, liability) => acc + (liability.amount || 0), 0)
  const netWorth = totalAssets - totalLiabilities

  const baseFrequency = ch?.pfs?.income?.find((source) => source.type === "salary")?.frequency || "monthly"
  const totalIncome = (ch?.pfs?.income || []).reduce((acc, incomeSource) => {
    return acc + (incomeSource.amount || 0) * frequencyMultiplierToAnnual[incomeSource.frequency ?? "monthly"]
  }, 0)
  const totalExpenses = (ch?.pfs?.expenses || []).reduce((acc, expense) => {
    return acc + (expense.amount || 0) * frequencyMultiplierToAnnual[expense.frequency ?? "monthly"]
  }, 0)
  const balance = totalIncome - totalExpenses
  const balanceLabel = balance >= 0 ? "Surplus" : "Deficit"
  const postfix = tt({ id: `${baseFrequency}-option-postfix` })
  const currency = tt({ id: "currency" })
  return {
    balanceLabel,
    raw: {
      assets: totalAssets,
      liabilities: totalLiabilities,
      netWorth: netWorth,
      incomeSources: totalIncome / frequencyMultiplierToAnnual[baseFrequency],
      expenses: totalExpenses / frequencyMultiplierToAnnual[baseFrequency],
      balance: balance / frequencyMultiplierToAnnual[baseFrequency],
      totalIncome,
      totalExpenses
    },
    formatted: {
      assets: currency + numberFormatter.format(totalAssets),
      liabilities: currency + numberFormatter.format(totalLiabilities),
      netWorth: (netWorth < 0 ? "-" : "") + currency + numberFormatter.format(Math.abs(netWorth)),
      incomeSources: currency + numberFormatter.format(totalIncome / frequencyMultiplierToAnnual[baseFrequency]) + postfix,
      expenses: currency + numberFormatter.format(totalExpenses / frequencyMultiplierToAnnual[baseFrequency]) + postfix,
      balance: (balance < 0 ? "-" : "") + currency + numberFormatter.format(Math.abs(balance / frequencyMultiplierToAnnual[baseFrequency])) + postfix
    }
  }
}

export default useClientFinances
