import { useParams } from "react-router"
import { useMemo } from "react"
import clsx from "clsx"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import useClient from "../../../hooks/useClient"
import LoadingError from "../../../components/ClientProfile/LoadingError/LoadingError"
import useHousehold from "../../../hooks/useHousehold"
import DecisionsTable from "./components/DecisionsTable/DecisionsTable"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"

const RMJYourDecisions = ({ translationContext = "client" }: { translationContext?: "advisor" | "client" }) => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)

  const title = useMemo(() => {
    const completedMembers = household?.members?.filter((member) => latest(member.client, "risk")?.played)

    if (clientId || (householdId && completedMembers && completedMembers.length === 1)) {
      const relevantClient = clientId ? client : completedMembers?.find((member) => latest(member.client, "risk")?.risk.results?.R.cceiScore)?.client
      const decisionConsistency = relevantClient ? latest(relevantClient, "risk")?.risk.results?.R.cceiScore : undefined
      if (decisionConsistency === undefined || decisionConsistency === null) {
        return "-"
      }
      return decisionConsistency >= 75
        ? tt({
            id: `your-decisions-title-high-consistency-individual-${translationContext}`,
            message: "You have a good understanding of risk and reward",
            values: { firstName: client?.firstName ?? completedMembers![0].client.firstName }
          })
        : tt({
            id: `your-decisions-title-low-consistency-individual-${translationContext}`,
            message: "Here are the decisions you made in the activity",
            values: { firstName: client?.firstName ?? completedMembers![0].client.firstName }
          })
    }
    if (completedMembers && completedMembers.length > 1) {
      const scores = completedMembers.map((member) => latest(member.client, "risk")?.risk.results?.R.cceiScore)
      if (scores.every((score) => score !== undefined && score !== null)) {
        return scores.every((score) => score >= 75)
          ? tt({
              id: `your-decisions-title-high-consistency-household-${translationContext}`,
              message: "You both have a good understanding of risk and reward",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            })
          : tt({
              id: `your-decisions-title-low-consistency-household-${translationContext}`,
              message: "Here are the decisions you each made in the activity",
              values: { firstName: completedMembers[0].client.firstName, secondName: completedMembers[1].client.firstName }
            })
      }
    }
  }, [household?.members, clientId, householdId, client, translationContext])

  return (
    <div className="w-full h-full flex flex-col overflow-y-auto no-scrollbar px-4">
      <p className="text-sec text-main-600 mb-1 uppercase">{household ? "Your decision patterns" : "Your decision pattern"}</p>
      <h4 className="text-h3 font-semibold text-main-600 mb-8">{title}</h4>
      {(isClientLoading || isHouseholdLoading) && (
        <div className="absolute top-0 bottom-0  left-0 right-0 flex justify-center items-center">
          <Loading />
        </div>
      )}
      <div className="grow">
        {client && <DecisionsTable clients={[client]} />}
        {household && (
          <div>
            <DecisionsTable
              clients={household.members.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played).map(({ client }) => client)}
            />
            <div className="flex justify-center gap-4 pt-10">
              {household.members
                .filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
                .map(({ client }, i) => {
                  const colorIndex = i % household.members.length
                  return (
                    <p className="flex items-center gap-2 text-sm" key={client._id}>
                      <span
                        className={clsx("w-2 h-2 block rounded-full", {
                          "bg-avatar-0-500": colorIndex === 0,
                          "bg-avatar-1-500": colorIndex === 1
                        })}
                      />
                      {client.firstName}
                    </p>
                  )
                })}
            </div>
          </div>
        )}
        <div role="alert">
          {clientLoadError && <LoadingError message={"Could not load client"} />}
          {householdLoadError && <LoadingError message={"Could not load household"} />}
        </div>
      </div>
    </div>
  )
}

export default RMJYourDecisions
