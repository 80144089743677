import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import axiosInstance from "../../../api/axiosInstance"
import Checkbox from "../../../components/Checkbox/Checkbox"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import { latestGamesByType, supportedGames } from "../../../lib/clients"
import { tt } from "../../../lib/translations"
import { Client, GameType } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { useTheme } from "../../../contexts/ThemeContext"

const EFPReprofileModal = ({
  client,
  household,
  onClose,
  onConfirm
}: {
  client?: Client
  household?: Household
  onClose: () => void
  onConfirm?: (updatedClient: Client) => void
}) => {
  const theme = useTheme()
  const { firm } = useContext(FirmContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [error, setError] = useState<string>("")
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [clientUpdateState, setClientUpdateState] = useState<"updating" | "success" | "error" | undefined>()
  const [selectedGames, setSelectedGames] = useState<{ [clientId: string]: GameType[] }>({})

  const onReprofile = () => {
    setShouldValidate(true)
    const errs = validateForm()
    if (!errs) {
      setClientUpdateState("updating")
      Promise.all(
        Object.keys(selectedGames).map((clientId) => {
          const params = new URLSearchParams({})
          selectedGames[clientId]?.forEach((value) => {
            params.append("gameType", value)
          })
          return axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/efp/reprofile?${params}`, {}).then((res) => {
            replace(res.data as Client)
          })
        })
      )
        .then(() => {
          onClose()
          onConfirm?.(client!)
        })
        .catch((error) => {
          setClientUpdateState("error")
          console.error("Error updating clients:", error)
        })
    }
  }

  const validateForm = useCallback(() => {
    if (Object.keys(selectedGames).length === 0) {
      return "Please select at least one game"
    }
  }, [selectedGames])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setError(nextErrors ?? "")
    }
  }, [shouldValidate, validateForm])

  const onChange = (client: Client, gameType: GameType, isSelected: boolean) => {
    setSelectedGames((prevSelectedGames) => {
      const currentGames = prevSelectedGames[client._id] || []
      const updatedGames = isSelected
        ? currentGames.includes(gameType)
          ? currentGames
          : [...currentGames, gameType]
        : currentGames.filter((g) => g !== gameType)
      return {
        ...prevSelectedGames,
        [client._id]: updatedGames
      }
    })
  }

  const onSelectAll = (client: Client, isSelected: boolean) => {
    const available = availableGames(client)
    setSelectedGames((prevSelectedGames) => {
      return {
        ...prevSelectedGames,
        [client._id]: isSelected ? [...available] : []
      }
    })
  }

  const availableGames = useCallback(
    (client: Client) => {
      const availableGames = supportedGames(client, firm)
      const gameByType = latestGamesByType(client, firm)
      const orderedGames = availableGames
        .filter((game) => gameByType[game]?.played && gameByType[game]?.status !== "UP_FOR_REVIEW")
        .sort((a, b) => {
          const order = theme.games.map((game) => game.type)
          return order.indexOf(a) - order.indexOf(b)
        })
      return orderedGames
    },
    [firm, theme.games]
  )

  return (
    <div className="reprofile-efp-modal-wrapper flex flex-col items-start gap-y-6 w-full overflow-y-scroll no-scrollbar max-h-full">
      <h2 className="text-h2 text-left font-semibold text-main-600">
        {household ? "Which activities would you like this household to re-profile for?" : "Which activities would you like this client to re-profile for?"}
      </h2>
      <p>
        {household
          ? "You’ll need to re-invite your household to complete the activities. Their current results will be recorded, but replaced with the new results for the provision of advice."
          : "You’ll need to re-invite your client to complete the activities. Their current results will be recorded, but replaced with the new results for the provision of advice."}
      </p>
      <div className="w-full flex flex-col gap-y-2">
        {household ? (
          household.members
            .filter(({ client }) => availableGames(client).length > 0)
            .map(({ client }) => (
              <div key={client._id}>
                <div className="flex justify-between p-1">
                  <h3 className="text-sec font-semibold text-main-600">Re-profile {client?.firstName}</h3>
                  <Checkbox
                    name={`select-all-${client._id}`}
                    label="Select all"
                    className="flex-row-reverse"
                    onChange={(event) => onSelectAll(client!, event.target.checked)}
                    checked={!!selectedGames?.[client._id] && availableGames(client).every((g) => selectedGames[client?._id].includes(g))}
                  />
                </div>
                <div className="w-full bg-surface-100 py-3 pl-4">
                  {availableGames(client).map((game) => {
                    return (
                      <Checkbox
                        key={game}
                        name={`${game}-${client._id}`}
                        value={game}
                        checked={!!selectedGames?.[client?._id] && selectedGames?.[client._id].includes(game)}
                        onChange={(event) => onChange(client!, game, event.target.checked)}
                        className="flex-row-reverse justify-between w-full p-1"
                        label={tt({ id: `game-${game}-title` })}
                      />
                    )
                  })}
                </div>
              </div>
            ))
        ) : (
          <>
            {client && (
              <div className="flex w-full justify-between pr-5">
                <h3 className="text-sec font-semibold text-main-600">Re-profile {client?.firstName}</h3>
                <Checkbox
                  name={`select-all-${client._id}`}
                  label="Select all"
                  className="flex-row-reverse"
                  onChange={(event) => onSelectAll(client, event.target.checked)}
                  checked={!!selectedGames?.[client?._id] && availableGames(client).every((g) => selectedGames[client._id].includes(g))}
                />
              </div>
            )}

            <div className="w-full bg-surface-100 py-3 px-4">
              {client &&
                availableGames(client).map((game) => {
                  return (
                    <Checkbox
                      key={game}
                      name={game}
                      checked={!!selectedGames?.[client._id] && selectedGames[client._id].includes(game)}
                      onChange={(event) => onChange(client!, game, event.target.checked)}
                      className="flex-row-reverse justify-between w-full p-1"
                      label={tt({ id: `game-${game}-title` })}
                    />
                  )
                })}
            </div>
          </>
        )}
      </div>
      <div role="alert">{clientUpdateState === "error" && <div className="text-error text-right">Error updating the client details</div>}</div>
      <div className="w-full flex flex-shrink-0 flex-wrap items-center justify-center">
        <div className="flex gap-5">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button
            onClick={onReprofile}
            disabled={
              clientUpdateState === "updating" || Object.keys(selectedGames).length === 0 || Object.values(selectedGames).flatMap((g) => g).length === 0
            }
            className="btn btn-primary btn-medium w-44"
          >
            {clientUpdateState === "updating" ? <Loading type="dots" /> : <span className="whitespace-nowrap">Re-profile</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

export default EFPReprofileModal
