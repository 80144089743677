import { useContext, useMemo, useState } from "react"
import { Client } from "../../../../../../models/Client"
import { Household } from "../../../../../../models/Household"
import PortfolioDetail from "../../../PortfolioDetail"
import { FirmContext } from "../../../../../../contexts/FirmContext"
import edit from "../../../../../../assets/icons/edit.svg"
import plus from "../../../../../advisor/assets/images/plus-circle.svg"
import Modal from "../../../../../../components/Modal/Modal"
import EditPortfolioModal from "../../../EditPortfolioModal"
import { update } from "../../../../../../api/clients"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { AuthContext } from "../../../../../../views/auth/AuthContext"
import { updateHousehold } from "../../../../../../api/households"
import { tt } from "../../../../../../lib/translations"
import { latest } from "../../../../../../lib/clients"

const SelectedInvestment = ({ client, household, translationContext = "profile" }: { client?: Client; household?: Household; translationContext?: string }) => {
  const { firm } = useContext(FirmContext)
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const clientOrHousehold = (client ?? household)!
  const status = clientOrHousehold.status
  const [portfolioUpdateState, setPortfolioUpdateState] = useState<"updating" | "success" | "error">()
  const [showEditPortfolioModal, setShowEditPortfolioModal] = useState(false)

  const currentPortfolio = useMemo(
    () => firm?.modelPortfolios?.find((modelPortfolio) => modelPortfolio.id === clientOrHousehold.currentPortfolio),
    [clientOrHousehold.currentPortfolio, firm?.modelPortfolios]
  )

  const game = latest(clientOrHousehold, "risk")
  const riskComfort = game?.portfolioMappings?.find(p => p.portfolio.id === currentPortfolio?.id)?.riskComfort

  const onUpdatePortfolio = (chosenPortfolio: string | null) => {
    setPortfolioUpdateState("updating")
    const handleSuccess = (updatedData: any) => {
      replace(updatedData)
      setPortfolioUpdateState("success")
      setShowEditPortfolioModal(false)
    }
    const handleError = (error: any) => {
      console.error("Error updating portfolio details", error)
      setPortfolioUpdateState("error")
    }
    if (household) {
      updateHousehold(
        sessionInfo!,
        household,
        household.members.map(() => ({ currentPortfolio: chosenPortfolio })),
        { currentPortfolio: chosenPortfolio }
      )
        .then(handleSuccess)
        .catch(handleError)
    } else if (client) {
      update(sessionInfo!, client._id, client, { currentPortfolio: chosenPortfolio }).then(handleSuccess).catch(handleError)
    } else {
      console.error("No household or client found to update")
      setPortfolioUpdateState("error")
    }
  }

  return (
    <div>
      {firm?.assetClasses && currentPortfolio ? (
        <div className="flex flex-col mt-6 gap-6">
          <div className="flex items-center">
            <p>
              {household
                ? tt({
                    id: `selected-investment-description-household-${translationContext}`,
                    values: { clients: `${household.members.map((m) => m.client.firstName).join(" and ")}`, portfolio: currentPortfolio.name }
                  })
                : tt({
                    id: `selected-investment-description-client-${translationContext}`,
                    values: { client: client?.firstName, portfolio: currentPortfolio.name }
                  })}
            </p>
          </div>
          <PortfolioDetail portfolio={currentPortfolio} riskComfort={riskComfort} showTitle={false} />
          <button
            className="btn btn-text btn-text-md w-fit flex items-center justify-center align-middle gap-1 text-interactive-600"
            onClick={() => setShowEditPortfolioModal(true)}
            disabled={status === "Archived"}
          >
            <img src={edit} alt="" aria-hidden />
            Edit investment
          </button>
        </div>
      ) : (
        <button
          onClick={() => setShowEditPortfolioModal(true)}
          disabled={status === "Archived"}
          className="btn btn-text btn-text-md leading-4 flex items-center justify-center align-middle gap-1 w-fit mt-6"
        >
          <img src={plus} alt="" aria-hidden />
          Add investment
        </button>
      )}
      {showEditPortfolioModal && (
        <Modal className="w-modal" handleClose={() => setShowEditPortfolioModal(false)}>
          <EditPortfolioModal
            onUpdate={onUpdatePortfolio}
            updateState={portfolioUpdateState}
            currentPortfolio={clientOrHousehold.currentPortfolio}
            onClose={() => setShowEditPortfolioModal(false)}
          />
        </Modal>
      )}
    </div>
  )
}

export default SelectedInvestment
