import { ActionItem } from "../models/ActionItem"
import { Client, GameType, PersonalFinancialDetails } from "../models/Client"
import { Household } from "../models/Household"
import { SessionInfo } from "../views/auth/AuthContext"
import axiosInstance from "./axiosInstance"
import { NewHouseholdRequest } from "./households"

export type NewClientRequest = {
  firstName?: string
  lastName?: string
  email?: string
  dob?: string
  investmentAmount?: number
  currentAnnualIncome?: number
  retirementIncomeGoal?: number
  annualInvestmentContribution?: number
  otherSourcesRetirementIncome?: number
}

export type ImportClientEntry = {
  id: number
  Firstname?: string
  Lastname?: string
  Email?: string
  DOB?: string
  errorMessage?: string
}

export type ImportClientsResponse = {
  imported: ImportClientEntry[]
  invalidData: ImportClientEntry[]
}

export type ImportFundEntry = {
  id: number,
  fundId: string,
  currentAllocation: number
}

export const createClient = async (advisorId: string, client: NewClientRequest) => {
  const res = await axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/${advisorId}/clients`, client)
    .then((res) => res.data)
  console.log("res in ts", res)
  return res as Client
}

export const importClients = async (advisorId: string, body: FormData) => {
  const res = await axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/advisor/${advisorId}/import-clients`, body)
    .then((res) => res.data)
  console.log("res in ts", res)
  return res as ImportClientsResponse
}

export const importPortfolio = async (_: SessionInfo, clientId: string, body: FormData): Promise<{ client: Client; missingFunds: ImportFundEntry[] }> => {
  return axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/import-portfolio`, body)
    .then((res) => res.data)
}

export type ClientUpdateRequest = {
  firstName?: string
  lastName?: string
  email?: string
  currentPortfolio?: string | null

  investmentAmount?: number | null
  primaryInvestmentGoal?: "retirementIncome" | "wealthAccumulation" | "retirementDrawdown" | null
  annualInvestmentContribution?: number | null

  gameTypes?: GameType[]

  dob?: string
  retirementAge?: number | null
  retirementIncomeGoal?: number | null
  otherSourcesRetirementIncome?: number | null
  pensionInRetirement?: number | null
  alreadyRetired?: boolean
  planningHorizon?: number
  estateGoal?: number | null
  wealthAccumulationGoal?: number | null
  wealthAccumulationYear?: number | null // 4 digit, static year


  investmentExperience?: string
  dependents?: string
  employmentType?: string
  jobType?: string
  currentAnnualIncome?: number | null

  timeHorizon?: string
  // this will be stored in activeGame.actionItems and games[-1].actionItems, it needs to send a complete list of action items
  actionItems?: ActionItem[]

  nextActivityDue?: string | null

  proposedPortfolio?: {
    fundId: string
    allocation: number
  }[]

  pfs?: PersonalFinancialDetails
}

export const update = async (_: SessionInfo, clientId: string, client: Client, fields: ClientUpdateRequest) => {
  const res =
    Object.keys(fields).filter((k) => fields[k as keyof ClientUpdateRequest] !== undefined).length > 0
      ? await axiosInstance
        .patch(
          `${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}`,
          fields
        )
        .then((res) => res.data as Client)
      : Promise.resolve(client)
  return res as Client
}

export const unlinkHousehold = (_: SessionInfo, householdId: string) => {
  return axiosInstance
    .delete(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/household/${householdId}/unmerge`
    )
    .then((res) => res.data)
}

export const merge = async (_: SessionInfo, advisorId: string, household: NewHouseholdRequest): Promise<Household> => {
  return await axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/household/merge?advisorId=${advisorId}`, household)
    .then((res) => res.data)
}

export const archive = (_: SessionInfo, clientId: string): Promise<Client> => {
  return axiosInstance
    .delete(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/archive`, {})
    .then((res) => res.data)
}

export const unArchive = (_: SessionInfo, clientId: string): Promise<Client> => {
  return axiosInstance
    .post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/unarchive`, {})
    .then((res) => res.data)
}

export const reprofile = async (_: SessionInfo, clientId: string, gameId: string) => {
  return axiosInstance
    .post(
      `${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${clientId}/game/reprofile?gameId=${gameId}`,
      {}
    )
    .then((res) => res.data)
}
