import Loading from "../Loading/Loading"
import checkmarkIcon from "../../../assets/icons/checkmark-circle.svg"
import alertIcon from "../../../assets/icons/info-alert.svg"

export type StatusAlert = {
  type: "success" | "activity" | "error"
  message: string
  timeout?: boolean
  onClose?: () => void
  location?: string | null
}

const ClientStatusAlert = ({ type, message }: StatusAlert) => {
  return (
    <div className="status-alert w-64 h-8 flex items-center justify-center border border-interactive-200 px-3 py-2">
      {type === "success" && <img alt="" className="w-4 h-4 mr-2" src={checkmarkIcon} aria-hidden />}
      {type === "activity" && <div className="w-6 mr-2"><Loading /></div>}
      {type === "error" && <img src={alertIcon} aria-hidden alt="" className="mr-2" />}
      <span className="text-sm text-interactive-500">{message}</span>
    </div>
  )
}

export default ClientStatusAlert
