import clsx from "clsx"
import { formatISO } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import axiosInstance from "../../api/axiosInstance"
import { updateHousehold } from "../../api/households"
import refreshIcon from "../../assets/icons/refresh.svg"
import { ClientHouseholdCacheContext } from "../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../contexts/FirmContext"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { latest, supportedGames } from "../../lib/clients"
import { formatDateLong } from "../../lib/date"
import { Client } from "../../models/Client"
import { Household } from "../../models/Household"
import EFPReprofileModal from "../../pages/clients/components/EFPReprofileModal"
import SendInvitesModal from "../../pages/clients/components/SendInvitesModal"
import { AuthContext } from "../../views/auth/AuthContext"
import AlertPopover, { AlertPopoverOptions } from "../AlertPopover/AlertPopover"
import DatePicker from "../DatePicker/DatePicker"
import Modal from "../Modal/Modal"
import ClientEFPStatusRow from "./ClientEFPStatusRow"
import ClientStatusAlert, { StatusAlert } from "./ClientStatusRow/ClientStatusAlert"
import Loading from "./Loading/Loading"

const HouseholdEFPSection = ({ household }: { household: Household }) => {
  const { replace } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const { sessionInfo } = useContext(AuthContext)
  const trackViewEvent = useTrackViewEvent()
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [sendInvitesModal, setSendInvitesModal] = useState<{ show: boolean; isReminder?: boolean }>()
  const [showReprofileModal, setShowReprofileModal] = useState(false)
  const nextActivityDueDate = useMemo(() => (household.nextActivityDue ? new Date(household.nextActivityDue) : undefined), [household.nextActivityDue])
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const { mutate: sendInvite, status: sendInviteUpdateState } = useMutation({
    mutationFn: ({ client, isReminder }: { client: Client; isReminder: boolean }) =>
      axiosInstance.put(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp/invite`, {}).then((res) => res.data as Client),
    onSuccess: (updated, { isReminder }) => {
      replace(updated)
      setInfoAlert({
        message: "Email has been sent",
        onClose: () => setInfoAlert(undefined),
        type: "success",
        location: isReminder ? "send_reminder" : "send_invite"
      })
    },
    onError: (err) => {
      console.error("Error sending invite", err)
    }
  })

  const { mutate: createEFP, status: createEFPUpdateState } = useMutation({
    mutationFn: ({ isReminder }: { isReminder: boolean }) =>
      Promise.all(
        household.members.map(({ client }) =>
          axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp`, {}).then((res) => res.data as Client)
        )
      ),
    onSuccess: (updatedClients, { isReminder }) => {
      updatedClients.forEach((updatedClient) => {
        replace(updatedClient)
        setInfoAlert({
          message: "Sending...",
          onClose: () => setInfoAlert(undefined),
          type: "activity",
          location: isReminder ? "send_reminder" : "send_invite"
        })
        sendInvite({ client: updatedClient, isReminder })
      })
    },
    onError: (err) => {
      console.error("Error confirming invites", err)
    }
  })

  const onConfirmSendInvites = ({ isReminder }: { isReminder: boolean }) => {
    setInfoAlert({
      message: "Sending invite...",
      timeout: false,
      type: "activity",
      location: "send_invite"
    })
    createEFP({ isReminder })
  }

  const onEditNextActivityDueDate = useCallback(
    (nextActivityDue: string) => {
      setIsTransitioning(true)
      updateHousehold(sessionInfo!, household, [], { nextActivityDue })
        .then((updatedHousehold) => {
          replace(updatedHousehold)
          setIsTransitioning(false)
          setInfoAlert({
            location: "nextActivityDue",
            message: "Date has been successfully updated",
            onClose: () => setInfoAlert(undefined),
            type: "success"
          })
        })
        .catch((error) => {
          console.error("Error setting the activity due date", error)
          setErrorMessage("Error setting the activity due date")
          setIsTransitioning(false)
        })
    },
    [household, sessionInfo, replace]
  )

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  const anyActivityWithNoGame = useMemo(
    () => household.members.some(({ client }) => supportedGames(client, firm).some((gameType) => !latest(client, gameType))),
    [firm, household.members]
  )

  const anyToBeReprofiled = useMemo(
    () => household.members.some(({ client }) => supportedGames(client, firm).some((gameType) => latest(client, gameType)?.status === "UP_FOR_REVIEW")),
    [firm, household.members]
  )

  const anyGamePlayedByAnyMember = useMemo(
    () =>
      household.members.some(({ client }) =>
        supportedGames(client, firm)
          .map((game) => latest(client, game))
          .some((game) => game?.played && game?.status !== "UP_FOR_REVIEW" && !game.summaryDate && !game.markedAsReviewedAt)
      ),
    [firm, household.members]
  )

  const anyActivityNotPlayed = useMemo(
    () =>
      household.members.some(({ client }) => supportedGames(client, firm).some((gameType) => latest(client, gameType) && !latest(client, gameType)!.played)),
    [firm, household.members]
  )

  const allIMDone = useMemo(
    () =>
      household.members.every(({ client }) =>
        supportedGames(client, firm).every((gameType) => latest(client, gameType) && latest(client, gameType)!.played && latest(client, gameType)!.summaryDate)
      ),
    [firm, household.members]
  )

  const anyIMDone = useMemo(
    () =>
      household.members.every(({ client }) =>
        supportedGames(client, firm)
          .map((gameType) => latest(client, gameType))
          .some((game) => game && game.played && (game.summaryDate || game.markedAsReviewedAt))
      ),
    [firm, household.members]
  )

  return (
    <>
      <div className="flex flex-col gap-x-3 gap-y-10">
        <div className="flex justify-between items-center">
          <div className="flex flex-col col-start-1 col-span-12 px-19">
            <h1 className="text-h2 text-main-600 font-semibold ">{`${household.name} household`}</h1>
            {household.nextActivityDue && nextActivityDueDate && (
              <div className="flex text-sm">
                <span>Re-profile due on</span>
                <DatePicker
                  id="nextActivityDue"
                  yearsInPast={0}
                  isInline={true}
                  customInput={<span className="text-sm text-link-600 hover:text-link-500 underline ml-1">{formatDateLong(household.nextActivityDue)}</span>}
                  onChange={(value: Date | null) => onEditNextActivityDueDate(formatISO(value!.setHours(0, 0, 0, 0)))}
                  value={nextActivityDueDate}
                  popperPlacement="bottom-start"
                />
                <AlertPopover options={alertPopoverOptions} show={!!infoAlert && infoAlert.location === "nextActivityDue"}>
                  {isTransitioning && (
                    <div className="h-4 mx-2">
                      <Loading />
                    </div>
                  )}
                </AlertPopover>
              </div>
            )}
          </div>
          {(sendInviteUpdateState === "error" || createEFPUpdateState === "error") && <div className="text-error">Error sending invites to the clients</div>}
          <div className="flex gap-x-5 pr-19">
            {anyGamePlayedByAnyMember && !allIMDone && (
              <Link
                className="btn btn-medium btn-primary !w-44 text-center animate-highlight-ping"
                to={`/households/${household._id}/rm/overview`}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  trackViewEvent({ action: "click", category: "present_results", label: household._id })
                  window.open(`/households/${household._id}/rm/overview`)
                }}
              >
                Present results
              </Link>
            )}
            {anyActivityWithNoGame || anyToBeReprofiled ? (
              <AlertPopover options={alertPopoverOptions} show={!!infoAlert}>
                <button
                  className={clsx("btn btn-medium w-44", anyGamePlayedByAnyMember ? "btn-secondary" : "btn-primary")}
                  onClick={() => setSendInvitesModal({ show: true })}
                >
                  Send invites
                </button>
              </AlertPopover>
            ) : anyActivityNotPlayed ? (
              <AlertPopover options={alertPopoverOptions} show={infoAlert?.location === "send_reminder"}>
                <button
                  className={clsx("btn btn-medium w-44", anyGamePlayedByAnyMember ? "btn-secondary" : "btn-primary")}
                  onClick={() => setSendInvitesModal({ show: true, isReminder: true })}
                >
                  Send reminder
                </button>
              </AlertPopover>
            ) : (
              <></>
            )}
            {(anyGamePlayedByAnyMember || anyIMDone) && (
              <button className="btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={() => setShowReprofileModal(true)}>
                <img src={refreshIcon} alt="" aria-hidden />
                Re-profile
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          {household.members.map(({ client }) => (
            <ClientEFPStatusRow key={client._id} client={client} availableGames={supportedGames(client, firm)} />
          ))}
        </div>

        <AnimatePresence>
          {sendInvitesModal?.show && (
            <Modal className="modal-medium" contentClassName="!px-10 w-full" handleClose={() => setSendInvitesModal({ show: false })}>
              <SendInvitesModal
                onConfirm={() => {
                  onConfirmSendInvites({ isReminder: sendInvitesModal.isReminder ?? false })
                }}
                onClose={() => setSendInvitesModal({ show: false })}
                household={household}
              />
            </Modal>
          )}
          {showReprofileModal && (
            <Modal contentClassName="!px-10 w-full" handleClose={() => setShowReprofileModal(false)}>
              <EFPReprofileModal onClose={() => setShowReprofileModal(false)} household={household} />
            </Modal>
          )}
        </AnimatePresence>
      </div>
      <div role="alert">{errorMessage && <p className="text-error">{errorMessage}</p>}</div>
    </>
  )
}

export default HouseholdEFPSection
