import { KeyboardEventHandler, PropsWithChildren, UIEventHandler, useCallback, useEffect } from "react"
import { createDomMotionComponent } from "framer-motion"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Close from "./assets/close.svg?react"
import clsx from "clsx"

export interface ModalProps {
  className?: string
  contentClassName?: string
  handleClose?: UIEventHandler
  handleConfirm?: UIEventHandler
}

const motion = {
  div: createDomMotionComponent("div")
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ children, className, contentClassName, handleClose, handleConfirm }) => {
  const handleKeyClose: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.keyCode === 27 && handleClose) {
        handleClose(e)
      } else if (e.keyCode === 13) {
        handleConfirm && handleConfirm(e)
      }
    },
    [handleClose, handleConfirm]
  )

  useEffect(() => {
    window.addEventListener<"keydown">("keydown", handleKeyClose as any) // TODO: I have code somewhere that does this properly in typescript
    return () => {
      window.removeEventListener("keydown", handleKeyClose as any)
    }
  }, [handleKeyClose])

  return (
    <>
      <div className="fixed inset-0 z-50 bg-black/75" onClick={handleClose} />
      <div className={clsx("modal fixed translate-y-1/2 bottom-1/2 translate-x-1/2 right-1/2 flex z-50 py-4 justify-center items-center", className)}>
        <motion.div
          key="modal-anim"
          initial={{ y: "160%" }}
          animate={{ y: 0, transition: { type: "tween" } }}
          exit={{ y: "160%", transition: { type: "tween" } }}
          aria-modal="true"
          role="dialog"
          className={clsx("relative flex flex-col max-h-full bg-white shadow-modal z-30 rounded items-center", className)}
        >
          {handleClose && (
            <div className="modal-close absolute right-0 top-0 py-2.5 px-3 z-10">
              <div className="cursor-pointer" onClick={handleClose} tabIndex={1}>
                <Close className="h-6 w-6" />
              </div>
            </div>
          )}
          <main className={clsx("relative grow w-full overflow-auto flex p-10 justify-center", contentClassName)}>{children}</main>
        </motion.div>
      </div>
    </>
  )
}

export default Modal
