import { Trans } from "@lingui/react"
import { clsx } from "clsx"
import React from "react"
import infoAlert from "../../../../../assets/icons/info-alert.svg"
import trash from "../../../../../assets/icons/trash.svg"
import NumberInput from "../../../../../components/NumberInput/NumberInput"
import { useTheme } from "../../../../../contexts/ThemeContext"
import useNumberFormatter from "../../../../../hooks/useNumberFormatter"
import { Liability, MAX_AMOUNT, PersonalFinancialDetails } from "../../../../../models/Client"
import PlaceholderRow from "../AssetsList/PlaceholderRow"
import { tt } from "../../../../../lib/translations"

interface Props {
  liability: Liability
  isEditing: boolean
  lastUpdatedDate?: string
  pfsData?: PersonalFinancialDetails
  onChange: (updated: PersonalFinancialDetails) => void
  onRemove?: (id?: string) => void
}

const LiabilityRow: React.FC<Props> = ({ liability, isEditing, lastUpdatedDate, pfsData, onChange, onRemove }) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  const isCustom = liability.type === "custom"
  const liabilityData = isCustom ? liability : pfsData?.liabilities?.find((a) => a.type === liability.type)
  const error = liabilityData?.amount && liabilityData.amount > MAX_AMOUNT ? tt({ id: "pfs-results-page-input-amount-range-error-text" }) : undefined

  if (!lastUpdatedDate && !isEditing) {
    return <PlaceholderRow type={liability.type} />
  }

  return (
    <div className="flex flex-col items-center p-3 border-b border-b-surface-300 text-sec">
      <div className="flex justify-between items-center w-full">
      <label className="whitespace-nowrap overflow-hidden text-ellipsis" htmlFor={isCustom ? liability.id! : liability.type}>
          {isCustom ? liability.name : <Trans id={`pfs-title-${liability.type}`} />}
        </label>
        {isEditing ? (
          <div className="flex gap-x-2 items-center">
            <NumberInput
              name={isCustom ? liability.id! : liability.type}
              className={clsx("!w-[186px]", { "mr-8": !isCustom })}
              inputFieldClassName="py-2.5 text-sec"
              value={liabilityData?.amount}
              onChange={(amount) => {
                onChange({
                  ...pfsData,
                  liabilities: !pfsData?.liabilities?.find((a) => a.type === liability.type && a.id === liability.id)
                    ? [...(pfsData?.liabilities ?? []), { type: liability.type, amount }]
                    : pfsData?.liabilities?.map((prevData) =>
                        prevData.type === liability.type && prevData.id === liability.id ? { ...prevData, amount } : prevData
                      )
                })
              }}
              prefix={
                <div className="pl-3 text-sec font-bold">
                  <Trans id="currency">$</Trans>
                </div>
              }
              error={!!error}
            />
            {isCustom && onRemove && (
              <button onClick={() => onRemove(liability.id)} className="mr-1">
                <img src={trash} alt="Remove liability" aria-hidden />
              </button>
            )}
          </div>
        ) : (
          <span className={clsx(!lastUpdatedDate ? "text-main-300" : "text-negative-600")}>
            <Trans id="currency">$</Trans>
            {liabilityData?.amount && numberFormatter.format(liabilityData.amount)}
          </span>
        )}
      </div>
      <div role="alert" className="w-full">
        {error && isEditing && (
          <div className="text-error flex gap-x-1 text-sm w-full text-left mt-1">
            <img src={infoAlert} alt="" aria-hidden />
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default LiabilityRow
