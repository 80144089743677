import clsx from "clsx"
import styled from "styled-components"
import { ChangeEventHandler, FocusEventHandler, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import infoAlert from "../../assets/icons/info-alert.svg"

export type Props = {
  autoComplete?: string
  className?: string
  disableAutoComplete?: boolean
  error?: string | ReactNode | boolean
  focusOnLoad?: boolean
  formatter?: (value: string) => string | undefined
  inputMode?: "numeric" | "text"
  isDisabled?: boolean
  label?: string | ReactNode
  mandatory?: boolean
  maxLength?: number
  name: string
  onBlur?: FocusEventHandler
  onChange?: (v: any) => void
  parser?: <T>(value: string) => T | undefined | null
  placeholder?: string
  prefix?: ReactNode
  suffix?: ReactNode
  note?: string | ReactNode,
  type?: string
  value?: string
  [x: string]: any
}

// use styled component so we can use as prop to set input or textarea
const Input = styled.input``

const TextInput = ({
  autoComplete,
  className,
  disableAutoComplete = false,
  error,
  focusOnLoad = false,
  formatter: formatterProp,
  inputMode = "text",
  isDisabled = false,
  label,
  mandatory,
  maxLength,
  name,
  onBlur: onBlurProp,
  onChange: onChangeProp,
  parser: parserProp,
  placeholder,
  prefix,
  suffix,
  note,
  type = "text",
  value,
  ...rest
}: Props) => {
  // set a default formatter if one is not provided
  const formatter = useCallback(
    (value?: string) => {
      return formatterProp && value !== undefined ? formatterProp(value) : value?.toString()
    },
    [formatterProp]
  )
  const parser = useCallback(
    (value: string) => {
      return parserProp ? parserProp(value) : value
    },
    [parserProp]
  )
  const [formattedValue, setFormattedValue] = useState(value ? formatter(value) : "")
  const [isFocused, setIsFocused] = useState(focusOnLoad)
  const [cursorPosition, setCursorPosition] = useState<[number | null, number | null] | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const input = evt.target as HTMLInputElement
    const nextValue = parser(input.value)
    const formattedCursorPosition = input.selectionStart || 0
    // Calculate the difference in length between formatted and original values
    const lengthDiff = (formatter(String(nextValue))?.length ?? 0) - input.value.length
    // Adjust the cursor position based on the length difference
    const adjustedCursorPosition = formattedCursorPosition + lengthDiff

    setCursorPosition([adjustedCursorPosition, adjustedCursorPosition])
    setFormattedValue(nextValue ? formatter(String(nextValue)) : "")
    onChangeProp && onChangeProp(nextValue)
  }

  useEffect(() => {
    const nextFormattedValue = formatter(value)
    if (nextFormattedValue !== undefined) {
      setFormattedValue(nextFormattedValue)
    } else {
      setFormattedValue("")
    }
  }, [formatter, value])

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus()
    }
  }, [isFocused])

  useLayoutEffect(() => {
    if (cursorPosition && inputRef.current) {
      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = cursorPosition
    }
  }, [cursorPosition])

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur: FocusEventHandler = (evt) => {
    setIsFocused(false)

    if (onBlurProp) {
      onBlurProp(evt)
    }
  }

  return (
    <div className={clsx("text-input w-full flex flex-col flex-shrink-0")} {...rest}>
      {label && (
        <label
          className={clsx("text-input-label w-full flex-shrink-0 text-sec mb-1 font-semibold", {
            "text-main-600": !isDisabled,
            "text-main-200": isDisabled
          })}
          htmlFor={name}
        >
          {label}
          {mandatory && <span className="text-negative-600">*</span>}
        </label>
      )}
      <div
        className={clsx("text-input-wrapper w-full h-full flex items-center border bg-white overflow-hidden", {
          "shadow-err border-red-500": !!error,
          "border-main-400": !error && !isDisabled,
          "border-main-200": !error && isDisabled,
          "shadow-focus": !error && isFocused
        })}
      >
        {prefix && <div className="text-input-prefix">{prefix}</div>}
        <Input
          as={type === "textarea" ? "textarea" : "input"}
          autoComplete={disableAutoComplete ? "off" : autoComplete ?? undefined}
          className={clsx(
            "text-input-input w-full h-full flex-grow outline-none bg-white px-2 py-3 text-p font-normal",
            {
              "text-main-500": !isDisabled,
              "text-main-200": isDisabled
            },
            className
          )}
          disabled={isDisabled}
          id={name}
          maxLength={maxLength}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          pattern={inputMode === "numeric" ? "[0-9,.']*" : undefined}
          placeholder={placeholder}
          ref={inputRef}
          type={type}
          value={formattedValue}
        />
        {suffix && <div className="text-input-suffix shrink-0 mr-2.5">{suffix}</div>}
      </div>
      <div role="alert">
        {error && typeof error !== "boolean" && (
          <div className="text-input-error flex items-center mt-1">
            <img alt="" className="text-input-error-icon mr-1" src={infoAlert} aria-hidden />
            <p className="text-input-error-text text-sm text-error font-normal">{error}</p>
          </div>
        )}
      </div>
      {note && <p className="text-input-note text-main-400 text-sm leading-2 mt-1">{note}</p>}
    </div>
  )
}

export default TextInput
