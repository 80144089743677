import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../components/TextInput/TextInput"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../contexts/FirmContext"
import { validateEmail } from "../../../lib/email"
import { tt } from "../../../lib/translations"
import { Client, GameType } from "../../../models/Client"
import { Household } from "../../../models/Household"
import { ClientUpdate } from "./ProfileOverview/components/EditProfileModal/EditProfileModal"
import { useTheme } from "../../../contexts/ThemeContext"
import Checkbox from "../../../components/Checkbox/Checkbox"
import clsx from "clsx"
import infoAlert from "../../../assets/icons/info-alert.svg"
import { Trans } from "@lingui/macro"
const SendInvitesModal = ({
  client,
  household,
  onClose,
  onConfirm
}: {
  client?: Client
  household?: Household
  onClose: () => void
  onConfirm: () => void
}) => {
  const { updateClient, updateHousehold, replace } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const [data, setData] = useState<{ [clientId: string]: ClientUpdate }>({})
  const [error, setError] = useState<string>("")
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [screen, setScreen] = useState<"confirm" | "add-email">("confirm")
  const [isAllChecked, setIsAllChecked] = useState<{ [clientId: string]: boolean }>({})
  const [selectedGames, setSelectedGames] = useState<{ [clientId: string]: GameType[] }>({})

  const isValid = useCallback(() => {
    return Object.values(selectedGames).every((gamesArray) => gamesArray.length >= 1)
  }, [selectedGames])

  useEffect(() => {
    if (household) {
      setData(
        household.members.reduce((acc, { client }) => {
          acc[client._id] = { email: client.email }
          return acc
        }, {} as { [clientId: string]: ClientUpdate })
      )
    } else if (client) {
      setData({ [client._id]: { email: client.email } })
    }
  }, [client, household])

  useEffect(() => {
    if (household) {
      setSelectedGames(
        household.members.reduce((acc, { client }) => {
          acc[client._id] = client.gameTypes ?? firm?.config?.supportedGames?.map((g) => g.type) ?? []
          return acc
        }, {} as { [clientId: string]: GameType[] })
      )
    } else if (client) {
      setSelectedGames({ [client._id]: client.gameTypes ?? firm?.config?.supportedGames?.map((g) => g.type) ?? [] })
    }
  }, [client, firm?.config?.supportedGames, household])

  useEffect(() => {
    setScreen(client?.email || household?.members.every(({ client }) => client.email) ? "confirm" : "add-email")
  }, [client, household])

  const { mutate: updateInviteEmails, status: updateState } = useMutation({
    mutationFn: () =>
      client
        ? (updateClient(client._id, {
            email: data[client._id].email
          }) as Promise<Client>)
        : (updateHousehold(household!._id, Object.values(data).map((d) => ({ email: d.email })) ?? [], {}) as Promise<Household>),
    onSuccess: (updated: Client | Household) => {
      replace(updated!)
      setScreen("confirm")
    },
    onError: (err) => {
      console.error("Error updating the emails", err)
    }
  })

  const { mutate: updateGameTypes } = useMutation({
    mutationFn: async () => {
      return client
        ? (updateClient(client._id, {
            gameTypes: selectedGames[client._id]
          }) as Promise<Client>)
        : (updateHousehold(household!._id, Object.values(selectedGames).map((d) => ({ gameTypes: d })) ?? [], {}) as Promise<Household>)
    },
    onSuccess: onClose
  })

  const handleSaveChanges = () => {
    if (screen === "add-email") {
      setShouldValidate(true)
      const errs = validateForm()
      if (!errs) {
        updateInviteEmails()
      }
    } else {
      const validateActivities = isValid()
      if (validateActivities) {
        updateGameTypes()
        onConfirm()
        onClose()
      }
    }
  }

  const validateForm = useCallback(() => {
    if (Object.values(data).some((d) => !d.email || (d.email?.trim() && !validateEmail(d.email.trim())))) {
      return "Please enter a valid email address"
    }
  }, [data])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setError(nextErrors ?? "")
    }
  }, [data, shouldValidate, validateForm])

  const gameOrder = theme.games.map((game) => game.type)
  const allGameTypes = useMemo(
    () =>
      firm?.config?.supportedGames
        ?.sort((a, b) => {
          return gameOrder.indexOf(a.type) - gameOrder.indexOf(b.type)
        })
        .map(({ type }) => type) ?? [],
    [firm?.config?.supportedGames, gameOrder]
  )

  const checkAllActivitiesHandler = useCallback(
    (id: string, isChecked: boolean) => {
      const updatedGames = isChecked ? allGameTypes : []
      const updatedCheckAll = { ...isAllChecked, [id]: isChecked }
      setSelectedGames((prev) => ({ ...prev, [id]: updatedGames }))

      if (household) {
        household.members.forEach(({ client }) => {
          if (client._id === id) {
            setSelectedGames((prev) => ({ ...prev, [id]: updatedGames }))
            setIsAllChecked(updatedCheckAll)
          }
        })
      }
    },
    [allGameTypes, isAllChecked, household]
  )

  const onChange = useCallback(
    (client: Client, gameType: GameType, isSelected: boolean) => {
      setSelectedGames((prevSelectedGames) => {
        const currentGames = prevSelectedGames[client._id] || []
        const updatedGames = isSelected
          ? currentGames.includes(gameType)
            ? currentGames
            : [...currentGames, gameType]
          : currentGames.filter((g) => g !== gameType)
        return {
          ...prevSelectedGames,
          [client._id]: updatedGames
        }
      })
      setIsAllChecked({ ...isAllChecked, [`${client._id}`]: false })
    },
    [isAllChecked]
  )

  useEffect(() => {
    if (household) {
      const updatedState = household.members.reduce<Record<string, boolean>>((acc, { client }) => {
        acc[client._id] = allGameTypes?.length === selectedGames[client._id]?.length
        return acc
      }, {})
      setIsAllChecked((prev) => ({ ...prev, ...updatedState }))
    }

    if (client) {
      setIsAllChecked((prev) => ({
        ...prev,
        [client._id]: allGameTypes?.length === selectedGames[client._id]?.length
      }))
    }
  }, [allGameTypes.length, client, household, selectedGames])

  return (
    <div className="send-invites-modal-wrapper flex flex-col items-start gap-y-6 w-full text-main-500">
      {screen === "add-email" ? (
        <>
          <h2 className="text-h2 text-left font-semibold text-main-600">
            {household
              ? `Please provide ${household.members.map(({ client }) => client.firstName).join(" and ")}'s email addresses`
              : `Please provide ${client!.firstName}'s email address`}
          </h2>
          <p>
            {household
              ? "Each household member will be sent an invite to complete their Economic Fingerprint."
              : `${client!.firstName} will be sent an invite to complete their Economic Fingerprint.`}
          </p>

          {household ? (
            household.members.map(({ client }) => {
              return (
                <TextInput
                  key={client._id}
                  label={`${client.firstName}'s email address`}
                  name="email"
                  onChange={(val) => setData((prev) => ({ ...prev, [client._id]: { email: val } }))}
                  value={data?.[client!._id!].email ?? client?.email}
                  error={error}
                  placeholder="email address"
                />
              )
            })
          ) : (
            <TextInput
              label={`${client!.firstName}'s email address`}
              name="email"
              onChange={(val) => setData((prev) => ({ ...prev, [client!._id]: { email: val } }))}
              value={data?.[client!._id!].email ?? client?.email}
              error={error}
              placeholder="email address"
            />
          )}
        </>
      ) : (
        <>
          <h2 className="text-h2 text-center font-semibold text-main-600">{household ? "Confirm invites" : "Confirm invite"}</h2>
          <p>
            {household
              ? "Each household member will be sent an invite to complete their Economic Fingerprint."
              : `${client!.firstName} will be sent an invite to complete their Economic Fingerprint.`}
          </p>
          <div className="w-full">
            <div className="flex w-full pr-4 mb-2">
              <h3 className="text-sec font-semibold text-main-600 w-full">Activities included</h3>
              {household ? (
                <div className="flex w-full pl-3">
                  {household.members.map(({ client, id }) => (
                    <div className="flex justify-end w-full" key={id}>
                      <Checkbox
                        checked={isAllChecked[id]}
                        onChange={(e) => checkAllActivitiesHandler(id, e.target.checked)}
                        className="justify-end flex flex-row-reverse"
                        name={client.firstName}
                        label={client?.firstName}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex justify-end w-full">
                  <Checkbox
                    checked={isAllChecked[client?._id ?? ""]}
                    onChange={(e) => checkAllActivitiesHandler(client?._id ?? "", e.target.checked)}
                    className="flex flex-row-reverse justify-end"
                    name="Select all"
                    label="Select all"
                  />
                </div>
              )}
            </div>

            <ul className="bg-surface-100 pb-1">
              {firm?.config?.supportedGames?.map((game, index) => {
                return (
                  <li className={clsx("flex items-center justify-between text-main-500 text-p px-4 pb-2", index === 0 && "pt-3")} key={game.type}>
                    {household ? (
                      <>
                        <p className="flex gap-x-3 items-center w-full">{tt({ id: `game-${game.type}-title` })}</p>
                        {household.members.map(({ client }, i) => (
                          <Checkbox
                            key={i}
                            className="w-1/2 justify-end"
                            name={game.type}
                            checked={selectedGames?.[client!._id]?.includes(game.type)}
                            onChange={(e) => onChange(client, game.type, e.target.checked)}
                            aria-label={`${tt({ id: `game-${game.type}-title` })}-${client.firstName}`}
                          />
                        ))}
                      </>
                    ) : (
                      <Checkbox
                        className="justify-end flex flex-row-reverse w-full"
                        name={game.type}
                        checked={selectedGames?.[client!._id]?.includes(game.type)}
                        onChange={(e) => onChange(client!, game.type, e.target.checked)}
                        label={tt({ id: `game-${game.type}-title` })}
                      />
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      )}
      {updateState === "error" && <div className="text-error text-right">Error updating the client details</div>}
      <div role="alert" className="text-input-error flex">
        {!isValid() && (
          <>
            <img alt="" className="text-input-error-icon mr-1" src={infoAlert} aria-hidden />
            <p className="text-input-error-text text-sm text-left text-error font-normal">
              {household ? (
                <Trans id="activity-settings-error-household-text">You must have at least one activity enabled for each household member</Trans>
              ) : (
                <Trans id="activity-settings-error-client-text">You must have at least one activity enabled</Trans>
              )}
            </p>
          </>
        )}
      </div>
      <div className="w-full flex flex-shrink-0 flex-wrap items-center justify-center pt-4">
        <div className="flex gap-5">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            disabled={updateState === "loading" || (screen === "add-email" && Object.values(data).some((d) => !d.email))}
            className="btn btn-primary btn-medium w-44"
          >
            {updateState === "loading" ? (
              <Loading type="dots" />
            ) : (
              <span className="whitespace-nowrap">{screen === "add-email" ? "Next" : household ? "Send invites" : "Send invite"}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SendInvitesModal
