/* eslint-disable react/jsx-no-target-blank */
import { Trans, t } from "@lingui/macro"
import clsx from "clsx"
import { AnimatePresence } from "framer-motion"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { investmentMeetingDone } from "../../../../api/advisors"
import { addNote } from "../../../../api/notes"
import ClientEFPSection from "../../../../components/ClientProfile/ClientEFPSection/ClientEFPSection"
import ClientGameActivityRow from "../../../../components/ClientProfile/ClientStatusRow/ClientGameActivityRow"
import ClientGameStatusRow from "../../../../components/ClientProfile/ClientStatusRow/ClientGameStatusRow"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Modal from "../../../../components/Modal/Modal"
import ReportsList, { ReportsListItem } from "../../../../components/ReportsList/ReportsList"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../contexts/FirmContext"
import { useTheme } from "../../../../contexts/ThemeContext"
import useTrackViewEvent from "../../../../hooks/useTrackViewEvent"
import useWindowFocus from "../../../../hooks/useWindowFocus"
import { latestGamesByType, supportedGames } from "../../../../lib/clients"
import { tt } from "../../../../lib/translations"
import { Client, Game } from "../../../../models/Client"
import { AuthContext } from "../../../../views/auth/AuthContext"
import ReprofileConfirmationModal from "../../../advisor/components/ReprofileConfirmationModal/ReprofileConfirmationModal"
import AltruismScore from "./components/AltruismScore/AltruismScore"
import FundsMatch from "./components/FundsMatch/FundsMatch"
import Notes from "./components/Notes/Notes"
import PersonaSummary from "./components/PersonaSummary/PersonaSummary"
import PortfolioCompare from "./components/PortfolioCompare/PortfolioCompare"
import RegulatoryReporting from "./components/RegulatoryReporting/RegulatoryReporting"
import ScoreTrends from "./components/ScoreTrends/ScoreTrends"
import Section from "./components/Section"
import settingsIcon from "../../../../assets/icons/settings.svg"
import ActivitySettingsModal from "../ActivitySettingsModal"
import SelectedInvestment from "./components/SelectedInvestment/SelectedInvestment"

export type GameTour = {
  show: boolean
  totalSteps: number
  currentStep: number
}

const Profile = ({ client }: { client: Client }) => {
  const { firstName, lastName, games, status } = client
  const [isNoteSaving, setIsNoteSaving] = useState<boolean>(false)
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false)
  const { firm } = useContext(FirmContext)
  const [showActivitySettingsModal, setShowActivitySettingsModal] = useState(false)
  const [showReprofileModalClient, setShowReprofileModalClient] = useState<{ client: Client; game: Game }>()
  const trackViewEvent = useTrackViewEvent()
  const theme = useTheme()
  const isWindowInFocus = useWindowFocus()

  const reports = useMemo(() => {
    const nextReports: ReportsListItem[] = []
    games?.forEach((game) => {
      if (game.gameReportUrl) {
        nextReports.push({
          date: game.played,
          id: `${game.experimentGroupId}-${game.played}`,
          link: (
            <a
              className="btn btn-text btn-text-md"
              onClick={() => trackViewEvent({ action: "click", category: "check_results", label: client._id })}
              href={game.gameReportUrl}
              rel="noreferrer"
              target="_blank"
            >
              View report
            </a>
          ),
          title: game.migratedAt
            ? "TrueProfile Risk Report"
            : tt({ id: "report-history-pre-meeting", message: "Risk Preferences Report", values: { firstName: client.firstName, lastName: client.lastName } })
        })
      }
      if (game.summaryReportUrl) {
        nextReports.push({
          date: game.summaryDate,
          id: `${game.experimentGroupId}-${game.summaryDate}`,
          link: (
            <a
              className="btn btn-text btn-text-md"
              onClick={() => trackViewEvent({ action: "click", category: "check_results", label: client._id })}
              href={game.summaryReportUrl}
              rel="noreferrer"
              target="_blank"
            >
              View report
            </a>
          ),
          title: game.migratedAt
            ? "TrueProfile Portfolio Report"
            : tt({
                id: "report-history-post-meeting",
                message: "Investment Meeting Report",
                values: { firstName: client.firstName, lastName: client.lastName }
              })
        })
      }
    })
    return nextReports
  }, [client._id, client.firstName, client.lastName, games, trackViewEvent])

  /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
  const riskResults = useCallback(() => {
    return games
      ? games
          .filter((game) => game.gameType === "risk" && game.played)
          .flatMap((game) => ({
            date: game.played,
            attitudeToRisk: game.risk?.results?.R.riskTolerance!,
            sensitivityToLoss: game.risk?.results?.R.lossAversion!
          }))
      : []
  }, [games])

  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)

  const onAddNote = useCallback(
    (note: string) => {
      setIsNoteSaving(true)
      addNote(sessionInfo!, "client", client._id!, note).then((res) => {
        replace(res)
        setIsNoteSaving(false)
      })
    },
    [sessionInfo, client._id, replace]
  )

  const generateReport = useCallback(() => {
    setIsGeneratingReport(true)
    investmentMeetingDone(client.advisorId, "client", client._id!, { generateReport: true })
      .then((res) => {
        replace(res)
        setTimeout(() => {
          setIsGeneratingReport(false)
        }, 1000)
      })
      .catch((error) => {
        console.error("error generating the report", error)
        throw error
      })
  }, [client.advisorId, client._id, replace])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (theme.pages.profile.autoUpToDate && isWindowInFocus && client?.status === "New results available") {
        investmentMeetingDone(client.advisorId, "client", client._id)
          .then((updatedClient) => {
            replace(updatedClient)
          })
          .catch((error) => {
            console.error("error marking client as investment meeting done", error)
            throw error
          })
      }
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [client, firm, isWindowInFocus, replace, sessionInfo, theme.pages.profile.autoUpToDate])

  const availableGames = supportedGames(client, firm)
  const gameByType = latestGamesByType(client, firm)

  return (
    <div className="w-full">
      <h1 className="sr-only">Client profile</h1>
      {(!firm?.config?.supportedGames || firm?.config?.supportedGames?.length === 0 || theme.pages.profile.multiActivityView === "list") && (
        <Section
          title={`${firstName} ${lastName}`}
          titleElement="h2"
          button={
            theme.gameType === "esg" &&
            ["New results available", "Up to date", "Up for review"].includes(status!) &&
            gameByType.esg?.esg?.results &&
            (client.portfolio?.current && !gameByType.esg?.summaryReportUrl ? (
              <button onClick={generateReport} disabled={isGeneratingReport} className="btn btn-secondary btn-medium text-sec">
                {isGeneratingReport ? (
                  <div className="flex gap-x-1 justify-center items-center">
                    <span>Generating...</span>
                    <div>
                      <Loading />
                    </div>
                  </div>
                ) : (
                  "Generate report"
                )}
              </button>
            ) : client.portfolio?.current && gameByType.esg?.summaryReportUrl ? (
              <a href={gameByType.esg?.summaryReportUrl} className="btn btn-secondary btn-medium text-sec" target="_blank" rel="noreferrer">
                Download report
              </a>
            ) : (
              <a href={gameByType.esg?.gameReportUrl} className="btn btn-secondary btn-medium text-sec" target="_blank" rel="noreferrer">
                Download report
              </a>
            ))
          }
          className={clsx("px-19", availableGames.length === 1 && "pb-7")}
          showBottomBorder={availableGames.length === 1 ? theme.pages.profile.sections.clientStatus.showBottomBorder : false}
        >
          {availableGames.length === 1 && (
            <ClientGameStatusRow
              client={client}
              game={gameByType[availableGames[0]]}
              gameType={availableGames[0]}
              setShowReprofileModal={setShowReprofileModalClient}
            />
          )}
        </Section>
      )}

      {firm?.config?.supportedGames?.length === 1 &&
        gameByType[availableGames[0]] &&
        theme.pages.profile.sections.clientWarning.show &&
        ["New results available"].includes(status!) && (
          <Section className="px-19 py-5" showBottomBorder={theme.pages.profile.sections.clientWarning.showBottomBorder}>
            <ClientGameActivityRow client={client} game={gameByType[availableGames[0]]!} setShowReprofileModal={setShowReprofileModalClient} />
          </Section>
        )}

      {(firm?.config?.supportedGames?.length ?? 0) >= 1 &&
        (theme.pages.profile.multiActivityView === "list" ? (
          availableGames.map((game) => (
            <React.Fragment key={game}>
              {theme.pages.profile.sections.clientStatus.show && (
                <Section
                  title={tt({ id: `profile-page-status-${game}-title` })}
                  titleElement="h3"
                  className="px-19 py-10"
                  showBottomBorder={theme.pages.profile.sections.clientStatus.showBottomBorder}
                >
                  <ClientGameStatusRow key={game} client={client} game={gameByType[game]} gameType={game} setShowReprofileModal={setShowReprofileModalClient} />
                  {theme.pages.profile.sections.clientWarning.show && gameByType[game] && gameByType[game]!.played && !gameByType[game]!.summaryDate && (
                    <ClientGameActivityRow game={gameByType[game]!} client={client} setShowReprofileModal={setShowReprofileModalClient} />
                  )}
                </Section>
              )}
            </React.Fragment>
          ))
        ) : (
          <ClientEFPSection client={client} availableGames={availableGames} />
        ))}

      {(firm?.config?.supportedGames?.length ?? 0) >= 1 && (
        <Section
          className="py-6 px-19"
          showBottomBorder
          button={
            <button
              className="activity-settings-btn btn btn-text btn-text-md flex items-center align-middle gap-x-2"
              onClick={() => setShowActivitySettingsModal(true)}
            >
              <img className="activity-settings-btn__img" src={settingsIcon} alt="" aria-hidden />
              Activity settings
            </button>
          }
        />
      )}

      {theme.pages.profile.sections.selectedPortfolio.show && (
        <Section
          title="Selected investment"
          titleElement="h3"
          className="px-19 py-9"
          showBottomBorder={theme.pages.profile.sections.selectedPortfolio.showBottomBorder}
        >
          <SelectedInvestment client={client} />
        </Section>
      )}

      {theme.pages.profile.sections.portfolioCompare.show &&
        ["New results available", "Up to date", "Up for review"].includes(status!) &&
        gameByType.esg?.esg.results && (
          <Section
            title={<Trans id="portfolio-compare-heading">{client.firstName}'s portfolio</Trans>}
            titleElement="h3"
            className="px-19 py-10"
            showBottomBorder={theme.pages.profile.sections.portfolioCompare.showBottomBorder}
          >
            <PortfolioCompare client={client} />
          </Section>
        )}

      {theme.pages.profile.sections.personaSummary.show &&
        ["New results available", "Up to date", "Up for review"].includes(status!) &&
        gameByType.esg?.esg.results && (
          <Section className="px-19 py-10" showBottomBorder={theme.pages.profile.sections.personaSummary.showBottomBorder}>
            <PersonaSummary client={client} />
          </Section>
        )}

      {theme.pages.profile.sections.altruismScore.show &&
        ["New results available", "Up to date", "Up for review"].includes(status!) &&
        gameByType.esg?.esg.results?.S2 && (
          <Section className="px-19 py-10" showBottomBorder={theme.pages.profile.sections.altruismScore.showBottomBorder}>
            <AltruismScore client={client} />
          </Section>
        )}

      {theme.pages.profile.sections.fundsMatch.show &&
        ["New results available", "Up to date", "Up for review"].includes(status!) &&
        gameByType.esg?.esg.results && (
          <Section className="px-19 py-10" showBottomBorder={theme.pages.profile.sections.fundsMatch.showBottomBorder}>
            <FundsMatch client={client} />
          </Section>
        )}

      {theme.pages.profile.sections.scoreTrends.show && (
        <Section title="Score trends" titleElement="h3" className="px-19 py-9" showBottomBorder={theme.pages.profile.sections.scoreTrends.showBottomBorder}>
          <div className="relative inset-0 mt-6">
            <ScoreTrends riskResults={riskResults()} />
          </div>
        </Section>
      )}

      {theme.pages.profile.sections.reportHistory.show && (
        <Section
          title={t({ id: "client-status-report-history", message: "Report history" })}
          titleElement="h3"
          className="px-19 py-9"
          showBottomBorder={theme.pages.profile.sections.reportHistory.showBottomBorder}
        >
          <ReportsList reports={reports} />
        </Section>
      )}

      {theme.pages.profile.sections.activityAndNotes.show && (
        <Section
          title={<Trans id="client-activity-tracking-heading">Activity tracking + notes</Trans>}
          titleElement="h3"
          className="px-19 py-9"
          showBottomBorder={theme.pages.profile.sections.activityAndNotes.showBottomBorder}
        >
          <Notes isDisabled={status === "Archived"} profile={client} onAddNote={onAddNote} isNoteSaving={isNoteSaving} />
        </Section>
      )}

      {theme.pages.profile.sections.regulatoryReporting.show && (
        <Section
          title="Regulatory reporting"
          titleElement="h3"
          className="px-19 pt-9"
          showBottomBorder={theme.pages.profile.sections.regulatoryReporting.showBottomBorder}
        >
          <RegulatoryReporting client={client} game={gameByType.risk} />
        </Section>
      )}

      <AnimatePresence>
        {showActivitySettingsModal && (
          <Modal className="max-w-screen-md" contentClassName="!px-10 w-full w-modal" handleClose={() => setShowActivitySettingsModal(false)}>
            <ActivitySettingsModal onClose={() => setShowActivitySettingsModal(false)} client={client} />
          </Modal>
        )}
        {showReprofileModalClient && (
          <ReprofileConfirmationModal
            client={showReprofileModalClient.client}
            game={showReprofileModalClient.game}
            onClose={() => setShowReprofileModalClient(undefined)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Profile
