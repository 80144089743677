import { Trans } from "@lingui/react"
import { clsx } from "clsx"
import React from "react"
import infoAlert from "../../../../../assets/icons/info-alert.svg"
import trash from "../../../../../assets/icons/trash.svg"
import NumberInput from "../../../../../components/NumberInput/NumberInput"
import { useTheme } from "../../../../../contexts/ThemeContext"
import useNumberFormatter from "../../../../../hooks/useNumberFormatter"
import { Asset, MAX_AMOUNT, PersonalFinancialDetails } from "../../../../../models/Client"
import { tt } from "../../../../../lib/translations"
import PlaceholderRow from "./PlaceholderRow"

interface AssetRowProps {
  asset: Asset
  isEditing: boolean
  lastUpdatedDate?: string
  pfsData?: PersonalFinancialDetails
  onChange: (updated: PersonalFinancialDetails) => void
  onRemove?: (id?: string) => void
}

const AssetRow: React.FC<AssetRowProps> = ({ asset, isEditing, lastUpdatedDate, pfsData, onChange, onRemove }) => {
  const theme = useTheme()
  const numberFormatter = useNumberFormatter(theme.defaultLocale || "en-US")
  const isCustom = asset.type === "custom"
  const assetData = isCustom ? asset : pfsData?.assets?.find((a) => a.type === asset.type)
  const error = assetData?.amount && assetData.amount > MAX_AMOUNT ? tt({ id: "pfs-results-page-input-amount-range-error-text" }) : undefined

  if (!lastUpdatedDate && !isEditing) {
    return <PlaceholderRow type={asset.type} />
  }

  return (
    <div className="flex flex-col items-center p-3 border-b border-b-surface-300 text-sec">
      <div className="flex justify-between items-center w-full">
        <label className="whitespace-nowrap overflow-hidden text-ellipsis" htmlFor={isCustom ? asset.id! : asset.type}>
          {isCustom ? asset.name : <Trans id={`pfs-title-${asset.type}`} />}
        </label>
        {isEditing ? (
          <div className="flex gap-x-2 items-center">
            <NumberInput
              name={isCustom ? asset.id! : asset.type}
              className={clsx("!w-[186px]", { "mr-8": !isCustom })}
              inputFieldClassName="py-2.5 text-sec"
              value={assetData?.amount}
              onChange={(amount) => {
                onChange({
                  ...pfsData,
                  assets: !pfsData?.assets?.find((a) => a.type === asset.type && a.id === asset.id)
                    ? [...(pfsData?.assets ?? []), { type: asset.type, amount }]
                    : pfsData?.assets?.map((prevAsset) => (prevAsset.type === asset.type && prevAsset.id === asset.id ? { ...prevAsset, amount } : prevAsset))
                })
              }}
              prefix={
                <div className="pl-3">
                  <Trans id="currency" />
                </div>
              }
              error={!!error}
            />
            {isCustom && onRemove && (
              <button onClick={() => onRemove(asset.id)} className="mr-1">
                <img src={trash} alt="Remove asset" aria-hidden />
              </button>
            )}
          </div>
        ) : (
          <span className={clsx(!lastUpdatedDate ? "text-main-300" : "text-positive-600")}>
            <Trans id="currency">$</Trans>
            {assetData?.amount && numberFormatter.format(assetData.amount)}
          </span>
        )}
      </div>
      <div role="alert" className="w-full">
        {error && isEditing && (
          <div className="text-error flex gap-x-1 text-sm w-full text-left mt-1">
            <img src={infoAlert} alt="" aria-hidden />
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default AssetRow
