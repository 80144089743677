import { useContext } from "react"
import { FirmContext } from "../../../contexts/FirmContext"
import useClientFinances from "../../../hooks/useClientFinances"
import { tt } from "../../../lib/translations"
import { Client } from "../../../models/Client"
import { Household } from "../../../models/Household"
import Container from "./components/Container"
import Footer from "./components/Footer"
import PFSRowDetail, { PFSReportRow, ROWS_PER_PAGE } from "./PFSRowDetail"

const PageHeader = ({ balance, balanceLabel }: { balance: string; balanceLabel: string }) => {
  return (
    <div className="flex justify-between items-start w-full mb-8">
      <h2 className="reports-pfs-net-worth-page-title text-h4 font-semibold text-main-500 mb-1">Income statement</h2>
      <div className="flex flex-col">
        <p className="text-h2 font-semibold text-main-400">{balance}</p>
        <p className="text-sm text-right">{balanceLabel}</p>
      </div>
    </div>
  )
}

export const incomeExpensesPageData = (clientOrHousehold: Client | Household, totalIncome: number, totalExpenses: number) => {
  const incomes: PFSReportRow[] = (clientOrHousehold?.pfs?.income?.filter((a) => (a.amount ?? 0) > 0) ?? []).map((income) => ({
    type: "gain",
    content: income.type === "custom" ? income.name : tt({ id: `financial-statement-income-${income.type}-label` }),
    amount: income.amount ?? 0,
    frequency: income.frequency
  }))
  const baseFrequency = clientOrHousehold?.pfs?.income?.find((source) => source.type === "salary")?.frequency || "monthly"
  const expenses: PFSReportRow[] = (clientOrHousehold?.pfs?.expenses?.filter((a) => (a.amount ?? 0) > 0) ?? []).map((expense) => ({
    type: "loss",
    content: expense.type === "custom" ? expense.name : tt({ id: `financial-statement-expenses-${expense.type}-label` }),
    amount: expense.amount ?? 0,
    frequency: expense.frequency ?? "monthly"
  }))

  const data: PFSReportRow[] = [
    { type: "header", content: "Income" },
    ...incomes,
    {
      type: "gain-total",
      content: "Total income",
      amount: totalIncome,
      frequency: baseFrequency
    },
    { type: "header", content: "Expenses" },
    ...expenses,
    {
      type: "loss-total",
      content: "Total expenses",
      amount: totalExpenses,
      frequency: baseFrequency
    }
  ]

  const totalPages = Math.ceil(data.length / ROWS_PER_PAGE)
  return { totalPages, data }
}

const PFSIncomeExpensesPage = ({
  client,
  household,
  page,
  currentPageRows
}: {
  client?: Client
  household?: Household
  page: number
  currentPageRows: PFSReportRow[]
}) => {
  const clientOrHousehold = client ?? household
  const { firm } = useContext(FirmContext)
  const {
    formatted: { balance },
    balanceLabel
  } = useClientFinances(clientOrHousehold)

  return (
    <Container key={`page-${page}`} className="flex flex-col pt-10 relative px-10 pfs-net-worth-report-page">
      <div className="h-full">
        <PageHeader balance={balance} balanceLabel={balanceLabel} />
        <div className="flex flex-col gap-y-4">
          {currentPageRows.map((row, index) => (
            <PFSRowDetail key={index} row={row} />
          ))}
        </div>
      </div>
      <div className="relative h-full">
        <Footer pageNum={page} client={client!} household={household!} firm={firm} />
      </div>
    </Container>
  )
}

export default PFSIncomeExpensesPage
