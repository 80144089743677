import clsx from "clsx"
import { FocusEventHandler, ReactNode, useCallback, useMemo, useState } from "react"
import useNumberFormatter from "../../hooks/useNumberFormatter"
import { isNullOrUndefined } from "../../pages/rmjourney/goalExplorer/utils/validation"
import TextInput from "../TextInput/TextInput"

type Props = {
  className?: string
  inputFieldClassName?: string
  disableAutoComplete?: boolean
  error?: string | ReactNode
  isDisabled?: boolean
  label?: string | ReactNode
  locale?: string
  max?: number
  min?: number
  name: string
  onBlur?: FocusEventHandler
  onChange?: (val: number | undefined) => void
  prefix?: ReactNode
  suffix?: ReactNode
  rangeErrorText?: string
  value?: number | undefined
  mandatory?: boolean
  placeholder?: string
}

const NumberInput = ({
  className,
  disableAutoComplete = false,
  error,
  isDisabled = false,
  label,
  locale,
  max,
  min,
  name,
  onBlur,
  onChange: onChangeProp,
  prefix,
  suffix,
  rangeErrorText,
  value,
  mandatory,
  placeholder,
  inputFieldClassName
}: Props) => {
  const numberFormatter = useNumberFormatter(locale || "en-US")
  const [internalError, setInternalError] = useState<string | null>(null)
  const maxLength = useMemo(() => {
    if (!max) {
      return undefined
    }
    const numChars = max.toString().length
    return numChars + Math.floor(numChars / 3) // divide by 3 (000) to get number of separators
  }, [max])
  const rangeError = useMemo(() => {
    if (rangeErrorText) {
      return rangeErrorText
    }
    return `Please add a value between ${numberFormatter.format(min!)} and ${numberFormatter.format(max!)}`
  }, [numberFormatter, max, min, rangeErrorText])

  const formatter: (x: any) => string | undefined = useCallback(
    (value) => {
      if (value === undefined) {
        return ""
      } else if (!isNaN(value)) {
        return numberFormatter.format(value)
      }
    },
    [numberFormatter]
  )

  const parser = useCallback<(x: string) => number | string | null | undefined>(
    (value: string) => {
      value = value.replace(/[^0-9,']/g, '')
      const nextValue = numberFormatter.parse(value)
      return nextValue === undefined ? "" : nextValue
    },
    [numberFormatter]
  )

  const onChange = useCallback(
    (value: number | "") => {
      // can be empty string for no value. Convert to undefined
      const nextValue = value === "" ? undefined : value

      if (
        (nextValue !== null && nextValue !== undefined && ((min && nextValue < min) || (max && nextValue > max))) ||
        (min && mandatory && nextValue === undefined) ||
        (max && mandatory && nextValue === undefined)
      ) {
        setInternalError(rangeError)
      } else {
        setInternalError(null)
      }

      if (onChangeProp) {
        onChangeProp(nextValue)
      }
    },
    [max, min, mandatory, onChangeProp, rangeError]
  )

  return (
    <div className={clsx("number-input w-full flex flex-col flex-shrink-0", className)}>
      <TextInput
        className={inputFieldClassName}
        disableAutoComplete={disableAutoComplete}
        error={error || internalError}
        formatter={formatter}
        isDisabled={isDisabled}
        inputMode="numeric"
        label={label}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        parser={parser as any}
        prefix={prefix}
        suffix={suffix}
        mandatory={mandatory}
        placeholder={placeholder}
        value={!isNullOrUndefined(value) ? value?.toString() : undefined}
      />
    </div>
  )
}

export default NumberInput
