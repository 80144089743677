import { isDisabled } from "@testing-library/user-event/dist/utils"
import { format, subYears } from "date-fns"
import { useCallback, useContext, useEffect, useState } from "react"
import { ClientUpdateRequest, update } from "../../../../../../api/clients"
import AvatarBadges from "../../../../../../components/AvatarBadges/AvatarBadges"
import Loading from "../../../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../../../components/DatePicker/DatePicker"
import DollarIcon from "../../../../../../components/DolarIcon/DollarIcon"
import NumberInput from "../../../../../../components/NumberInput/NumberInput"
import TextInput from "../../../../../../components/TextInput/TextInput"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../../../contexts/FirmContext"
import { useTheme } from "../../../../../../contexts/ThemeContext"
import { validate } from "../../../../../../lib/clients"
import { Client } from "../../../../../../models/Client"
import { AuthContext } from "../../../../../../views/auth/AuthContext"
import { ClientErrors } from "../../../../../advisor/CreateIndividualClientPage"
import ProviderAlert from "./ProviderAlert"

export type ClientUpdate = {
  firstName?: string
  lastName?: string
  email?: string
  dob?: Date | null
  dobRaw?: string
  investmentAmount?: number | null
  currentAnnualIncome?: number | null
  retirementIncomeGoal?: number | null
  annualInvestmentContribution?: number | null
  otherSourcesRetirementIncome?: number | null
  retirementAge?: number | null
  investmentExperience?: string
  dependents?: string
  employmentType?: string
  jobType?: string
  nextActivityDue?: string
}

const EditProfileModal = ({ client, onClose }: { client: Client; onClose: () => void }) => {
  const { sessionInfo } = useContext(AuthContext)
  const { firm } = useContext(FirmContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [data, setData] = useState<ClientUpdate>({})
  const [errors, setErrors] = useState<ClientErrors>({})
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const clientDob = client.dob ? new Date(client.dob) : ""
  const [clientUpdateState, setClientUpdateState] = useState<"updating" | "success" | "error" | undefined>()
  const theme = useTheme()

  const handleSaveChanges = () => {
    setShouldValidate(true)
    const errs = validateForm()
    console.log("errors on click", errs)
    if (Object.values(errs).every((x) => x === null || x === undefined || x === "")) {
      const { dobRaw, ...clientRequest } = data
      const updateRequest: ClientUpdateRequest = {
        ...clientRequest,
        dob: data.dob ? format(data.dob, "yyyy-MM-dd") : dobRaw ?? undefined
      }
      setClientUpdateState("updating")
      update(sessionInfo!, client._id, client, updateRequest)
        .then((updatedClient) => {
          console.log("updated client", updatedClient)
          replace(updatedClient)
          setClientUpdateState("success")
          onClose()
        })
        .catch((error) => {
          console.error("error updating client details", error)
          setClientUpdateState("error")
        })
    }
  }

  const validateForm = useCallback(() => {
    const dob = data.dob ?? client.dob
    return validate(
      {
        firstName: data.firstName ?? client.firstName,
        lastName: data.lastName ?? client.lastName,
        email: data.email ?? client.email,
        dob: dob ? new Date(dob) : undefined,
        dobRaw: data.dobRaw,
        investmentAmount: data.investmentAmount ?? client.investmentAmount,
        currentAnnualIncome: data.currentAnnualIncome ?? client.currentAnnualIncome,
        retirementIncomeGoal: data.retirementIncomeGoal ?? client.retirementIncomeGoal,
        annualInvestmentContribution: data.annualInvestmentContribution ?? client.annualInvestmentContribution,
        otherSourcesRetirementIncome: data.otherSourcesRetirementIncome ?? client.otherSourcesRetirementIncome
      },
      firm!,
      client.advisorId
    )
  }, [data, firm, client])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setErrors(nextErrors)
    }
  }, [data, shouldValidate, validateForm])

  return (
    <div className="w-[600px]">
      <div className="flex flex-col w-full bg-white">
        <div className="modal-header flex flex-row items-center justify-items-start pb-4">
          <AvatarBadges clients={[client]} colorByStatus={theme.colors.avatarColorByStatus} />
          <h2 className="text-h2 text-main-600 font-semibold ml-3">Edit {`${client.firstName}'s profile`}</h2>
        </div>
        <ProviderAlert client={client} />
        <div className="overflow-y-auto p-1" style={{ maxHeight: "calc(100vh - 300px)" }}>
          <div className="grid grid-cols-1 gap-5 text-sec text-main-600">
            <TextInput
              label="First name"
              mandatory={!isDisabled}
              name="firstName"
              onChange={(val) => setData((prev) => ({ ...prev, firstName: val }))}
              value={data.firstName ?? client.firstName}
              error={errors.firstName}
              isDisabled={!!client.externalId}
            />
            <TextInput
              label="Last name"
              mandatory={!isDisabled}
              name="lastName"
              onChange={(val) => setData((prev) => ({ ...prev, lastName: val }))}
              value={data.lastName ?? client.lastName}
              error={errors.lastName}
              isDisabled={!!client.externalId}
            />
            <TextInput
              label="Email address"
              name="email"
              onChange={(val) => setData((prev) => ({ ...prev, email: val }))}
              value={data.email ?? client.email}
              error={errors.email}
              isDisabled={!!client.externalId}
            />
            {theme.pages.profile.sidebar.menu.editClientDetails.modal.fields.dateOfBirth && (
              <DatePicker
                id="dob"
                label="Date of birth"
                value={(data.dob ?? clientDob) || null}
                onChange={(value: Date | null) => {
                  setData((prev) => ({ ...prev, dob: value ? value : undefined }))
                }}
                onChangeRaw={(value: string) => {
                  setData((prev) => ({ ...prev, dobRaw: value }))
                }}
                yearsInAdvance={0}
                yearsInPast={125}
                defaultSelectedDate={subYears(new Date(), 40)}
                error={errors.dob}
              />
            )}
            <NumberInput
              name="investmentAmount"
              value={data.investmentAmount ?? client.investmentAmount}
              label="Investment amount"
              onChange={(val) => setData((prev) => ({ ...prev, investmentAmount: val || 0 }))}
              prefix={
                <div className="pl-3">
                  <DollarIcon />
                </div>
              }
              error={errors.investmentAmount}
            />
            {firm?.uiConfig?.RETIREMENT_GOAL_ATTRIBUTES && (
              <>
                <TextInput
                  label="Retirement age"
                  name="retirementAge"
                  onChange={(val) => setData((prev) => ({ ...prev, retirementAge: val ? +val : null }))}
                  inputMode="numeric"
                  value={(data.retirementAge || client.retirementAge || "").toString()}
                />
                {firm?.uiConfig?.CURRENT_INCOME && (
                  <NumberInput
                    label="Current annual income (before tax)"
                    name="currentAnnualIncome"
                    value={data.currentAnnualIncome ?? client.currentAnnualIncome}
                    onChange={(val) => setData((prev) => ({ ...prev, currentAnnualIncome: val || 0 }))}
                    disableAutoComplete
                    prefix={
                      <div className="pl-3">
                        <DollarIcon />
                      </div>
                    }
                    error={errors.currentAnnualIncome}
                  />
                )}
                <NumberInput
                  label="Retirement income goal"
                  name="retirementIncomeGoal"
                  value={data.retirementIncomeGoal ?? client.retirementIncomeGoal}
                  onChange={(val) => setData((prev) => ({ ...prev, retirementIncomeGoal: val || 0 }))}
                  disableAutoComplete
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  error={errors.retirementIncomeGoal}
                />
                <NumberInput
                  label="Annual investment contribution"
                  name="annualInvestmentContribution"
                  value={data.annualInvestmentContribution ?? client.annualInvestmentContribution}
                  onChange={(val) => setData((prev) => ({ ...prev, annualInvestmentContribution: val || 0 }))}
                  disableAutoComplete
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  error={errors.annualInvestmentContribution}
                />
                <NumberInput
                  label="Annual retirement income from other sources"
                  name="otherSourcesRetirementIncome"
                  value={data.otherSourcesRetirementIncome ?? client.otherSourcesRetirementIncome}
                  onChange={(val) => setData((prev) => ({ ...prev, otherSourcesRetirementIncome: val || 0 }))}
                  disableAutoComplete
                  prefix={
                    <div className="pl-3">
                      <DollarIcon />
                    </div>
                  }
                  error={errors.otherSourcesRetirementIncome}
                />
              </>
            )}
          </div>
        </div>
        {clientUpdateState === "error" && <div className="text-error text-right">Error updating the client details</div>}
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end pt-4">
          <div className="mx-auto flex gap-3">
            <button className="btn btn-medium btn-secondary w-44 text-interactive-500" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-medium w-44 btn-primary"
              onClick={handleSaveChanges}
              disabled={clientUpdateState === "updating" || Object.keys(data).length === 0}
            >
              {clientUpdateState === "updating" ? <Loading type="dots" /> : <span>Save changes</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProfileModal
