import clsx from "clsx"
import { FocusEventHandler, ReactNode, useState } from "react"
import infoAlert from "../../assets/icons/info-alert.svg"
import { FrequencySelect } from "../AmountFrequencyInput/AmountFrequencyInput"
import chevronDown from "./chevron-down.svg"

type Option = {
  id: string
  label: string
  value: string
}

type Props = {
  className?: string
  error?: string | ReactNode | boolean
  id: string
  isDisabled?: boolean
  label?: string | ReactNode
  name?: string
  onBlurProp?: (evt: React.FocusEvent<Element>) => void
  onChange?: (evt: React.ChangeEvent<HTMLSelectElement>) => void
  options: Option[] | FrequencySelect[]
  value?: string | number | undefined
}

const Select = ({ className, error, id, isDisabled = false, label, name, onBlurProp, onChange, options, value }: Props) => {
  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur: FocusEventHandler = (evt) => {
    setIsFocused(false)
    if (onBlurProp) {
      onBlurProp(evt)
    }
  }

  return (
    <>
      <div className={clsx("select-input flex flex-col h-full pr-2", className)}>
        {label && (
          <label className="select-input-label block w-full font-semibold text-p text-grey-500 mb-1" htmlFor={id}>
            {label}
          </label>
        )}
        <select
          className={clsx(
            "appearance-none w-full h-full border border-transparent outline-none px-3 py-2.5 flex items-center bg-no-repeat bg-right bg-white text-sec overflow-hidden whitespace-nowrap text-ellipsis",
            {
              "border-red-500 shadow-error": !!error,
              "border-grey-200 text-grey-300": isDisabled,
              "border-grey-400 text-grey-500": !error && !isDisabled,
              "shadow-focus border-interactive-focus": isFocused && !error && !isDisabled
            }
          )}
          disabled={isDisabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          style={{ backgroundImage: `url(${chevronDown})` }}
          value={value}
        >
          {options.map((option) => (
            <option key={option.id} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div role="alert">
          {error && typeof error !== "boolean" && (
            <div className="select-input-error flex items-center mt-1">
              <img alt="" className="select-input-error-icon mr-2 w-4 h-4" src={infoAlert} aria-hidden />
              <p className="select-input-error-text text-red-500 font-normal text-sec mt-px">{error}</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Select
