import { useEffect } from "react"

export const usePreventUnload = (isBlocking = false) => {
  useEffect(() => {
    if (isBlocking) {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        // Cancel the event
        e.preventDefault()
        e.returnValue = ""
      }
      window.addEventListener("beforeunload", handleBeforeUnload)
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload)
      }
    }
  }, [isBlocking])
}
