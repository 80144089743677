import React from 'react'
import SearchBox from "../../../advisor/components/SearchBox"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import NotImplemented from "../NotImplemented"

const GlobalSearch = () => {
  return (
    <div className="pg-ctr pg-ctr-py-lg h-full px-10 flex flex-col items-center justify-start gap-y-8">
      <h1 className="text-h1">Search</h1>
      <SearchBox className="w-1/2 mt-12 flex-0 !h-auto" onChange={() => {}} />
      <div className="flex gap-x-8">
        <label className="flex gap-x-1">
          <Checkbox name="licensees" checked />
          <span>Licensees</span>
        </label>
        <label className="flex gap-x-1">
          <Checkbox name="firms" checked />
          <span>Firms</span>
        </label>
        <label className="flex gap-x-1">
          <Checkbox name="advisors" checked />
          <span>Advisors</span>
        </label>
        <label className="flex gap-x-1">
          <Checkbox name="clients" checked />
          <span>Clients (or households)</span>
        </label>
        <label className="flex gap-x-1">
          <Checkbox name="users" checked={false} />
          <span>Users</span>
        </label>
      </div>
      <NotImplemented yet />
    </div>
  )
}

export default GlobalSearch