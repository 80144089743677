import { useContext, useState } from "react"
import RadioButton from "../../../components/RadioButton/RadioButton"
import { FirmContext } from "../../../contexts/FirmContext"
import Loading from "../../../components/ClientProfile/Loading/Loading"

const EditPortfolioModal = ({
  currentPortfolio,
  onUpdate,
  updateState,
  onClose
}: {
  currentPortfolio?: string
  onUpdate: (portfolio: string | null) => void
  updateState: "updating" | "success" | "error" | undefined
  onClose: () => void
}) => {
  const { firm } = useContext(FirmContext)
  const [portfolio, setPortfolio] = useState<string | null>(currentPortfolio || null)

  return (
    <div className="w-full flex flex-col gap-6">
      <h2 className="text-h2 font-semibold">Edit investment</h2>
      <fieldset>
        <legend className="text-sec leading-4 font-semibold mb-1">Select investment risk label</legend>
        <div className="flex flex-col gap-2">
          <RadioButton
            checked={!portfolio}
            id="no-portfolio"
            label={
              !currentPortfolio ? (
                <div className="flex flex-row items-center">
                  <span className="mr-2">No selected investment</span>
                  <span className="rounded-full h-1 w-1 mr-2 bg-avatar-0-500"></span>
                  <span className="text-avatar-0-500 text-xs uppercase">Current investment</span>
                </div>
              ) : (
                "No selected investment"
              )
            }
            name="edit-portfolio"
            onChange={() => setPortfolio(() => null)}
          />
          {firm?.modelPortfolios?.map((modelPortfolio) => (
            <RadioButton
              key={modelPortfolio.id}
              checked={portfolio === modelPortfolio.id}
              id={modelPortfolio.id}
              label={
                currentPortfolio && currentPortfolio === modelPortfolio.id ? (
                  <div className="flex flex-row items-center">
                    <span className="mr-2">{modelPortfolio.name}</span>
                    <span className="rounded-full h-1 w-1 mr-2 bg-avatar-0-500"></span>
                    <span className="text-avatar-0-500 text-xs uppercase font-semibold">Current investment</span>
                  </div>
                ) : (
                  <span>{modelPortfolio.name}</span>
                )
              }
              name="edit-portfolio"
              onChange={() => setPortfolio(modelPortfolio.id)}
            />
          ))}
        </div>
      </fieldset>
      {updateState === "error" && <p className="text-error">Error updating the portfolio</p>}
      <div className="flex gap-4 w-full m-auto text-center justify-center">
        <button className="btn btn-secondary btn-medium w-44" onClick={onClose}>
          Cancel
        </button>
        <button className="btn btn-primary btn-medium w-44" disabled={updateState === "updating"} onClick={() => onUpdate(portfolio)}>
          {updateState === "updating" ? <Loading type="dots" /> : "Update"}
        </button>
      </div>
    </div>
  )
}

export default EditPortfolioModal
