import { useCallback, useContext, useEffect, useState } from "react"
import { update } from "../../../api/clients"
import emailIcon from "../../../assets/icons/email.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import TextInput from "../../../components/TextInput/TextInput"
import { ClientHouseholdCacheContext } from "../../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../../contexts/ThemeContext"
import { validateEmail } from "../../../lib/email"
import { Client, Game } from "../../../models/Client"
import { AuthContext } from "../../../views/auth/AuthContext"
import { ClientUpdate } from "./ProfileOverview/components/EditProfileModal/EditProfileModal"

const AddClientEmailModal = ({ client, game, onClose, onSave }: { client: Client; game: Game, onClose: () => void; onSave: (updatedClient: Client) => void }) => {
  const { sessionInfo } = useContext(AuthContext)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const [data, setData] = useState<ClientUpdate>({})
  const [error, setError] = useState<string>("")
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [clientUpdateState, setClientUpdateState] = useState<"updating" | "success" | "error" | undefined>()
  const theme = useTheme()

  const handleSaveChanges = () => {
    setShouldValidate(true)
    const errs = validateForm()
    if (!errs) {
      setClientUpdateState("updating")
      update(sessionInfo!, client._id, client, { email: data.email?.trim() })
        .then((updatedClient) => {
          replace(updatedClient)
          setClientUpdateState("success")
          onSave(updatedClient) // Update email in parent component
          onClose()
        })
        .catch((error) => {
          console.error("error updating client details", error)
          setClientUpdateState("error")
        })
    }
  }

  const validateForm = useCallback(() => {
    if (data.email?.trim() && !validateEmail(data.email.trim())) {
      return "Please enter a valid email address"
    }
  }, [data])

  useEffect(() => {
    if (shouldValidate) {
      const nextErrors = validateForm()
      setError(nextErrors ?? "")
    }
  }, [data, shouldValidate, validateForm])

  return (
    <div className="add-client-email-wrapper flex flex-col items-center gap-6 w-full">
      <img className="add-client-email-img" src={theme.pages.profile.modals?.addClientEmail?.heroImage ?? emailIcon} alt="" aria-hidden />
      <h2 className="text-h2 text-center font-semibold text-main-600">Please provide your client's email address</h2>
      <TextInput
        label="Email address"
        name="email"
        onChange={(val) => setData((prev) => ({ ...prev, email: val }))}
        value={data.email ?? client.email}
        error={error}
      />
      {clientUpdateState === "error" && <div className="text-error text-right">Error updating the client details</div>}
      <div className="flex flex-shrink-0 flex-wrap items-center justify-end pt-4">
        <div className="flex gap-5">
          <button onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            disabled={clientUpdateState === "updating" || data.email?.trim().length === 0 || data.email === undefined}
            className="btn btn-primary btn-medium w-44"
          >
            {clientUpdateState === "updating" ? (
              <Loading type="dots" />
            ) : (
              <span className="whitespace-nowrap">Send {game?.sent ? "reminder" : "invite"}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddClientEmailModal
