import { latest } from "../../../../lib/clients"
import { customDateFormat } from "../../../../lib/date"
import { tt } from "../../../../lib/translations"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import goal from "../../../advisor/assets/images/goal.svg"
import SelectedInvestment from "../../../clients/components/Profile/components/SelectedInvestment/SelectedInvestment"
import CommentsSection from "../components/Comments/CommentsSection"

const GoalsTab = ({ client, household }: { client?: Client; household?: Household }) => {
  const clientOrHousehold = (client ?? household)!
  const game = latest(clientOrHousehold, "goals")
  const lastUpdatedDate = clientOrHousehold.goals?.updatedAt ?? game?.goals ? (game?.goals.updatedAt ?? game?.played) : undefined
  const goalDetails = clientOrHousehold?.goals?.goalDetails ?? []

  return (
    <>
      <div className="py-10 px-16">
        <h1 className="sr-only">Goals</h1>
        {!lastUpdatedDate || goalDetails.length === 0 ? (
          <section className="flex flex-col items-center gap-6 py-18 px-4 bg-surface-100">
            <div className="flex flex-col items-center text-center gap-3">
              <img className="w-25" src={goal} alt="" aria-hidden />
              <p className="font-semibold">
                {household
                  ? household.members.map((m) => m.client.firstName).join(" and ") + " have no goals yet, start by adding one."
                  : `${client?.firstName} has no goals yet, start by adding one.`}
              </p>
            </div>
            <button className="btn btn-secondary btn-medium w-44">Add goals</button>
          </section>
        ) : (
          <div className="flex flex-col gap-10">
            <p>Goals last updated on {customDateFormat(lastUpdatedDate)}</p>
            <div className="flex flex-col gap-6">
              <h2 className="text-h3 font-semibold">
                {household ? household.members.map((m) => m.client.firstName).join(" and ") + "'s goals" : `${client?.firstName}'s goals`}
              </h2>
              <div className="flex gap-5">
                {goalDetails.map((goal, i) => (
                  <div key={i} className="border border-highlight-200 p-3 w-1/4">
                    <p className="text-sec font-semibold">{goal.type === "createOwnGoal" ? goal.name : tt({ id: `profile-page-status-card-goals-goal-${goal.type}-title` })}</p>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-h3 font-semibold">Selected investment</h2>
              <SelectedInvestment client={client} household={household} translationContext="goals" />
            </div>
          </div>
        )}
      </div>
      <hr className="border-surface-300" />
      <CommentsSection gameType="goals" client={client} household={household} />
    </>
  )
}

export default GoalsTab
